export const votchStates = { 
    notVotched: 'Follow',
    pendingVotch: 'Pending Confirm',
    // acceptVotch: 'accept',
    cancelPending: 'cancel',
    // ignoreVotch: 'ignore',
    unVotch: 'Following ✔',
}

export function nextVotchState( action ) {
    // console.log('action', action)
    switch (action) {
        case votchStates.notVotched:
            return votchStates.pendingVotch
        case votchStates.unVotch:
            return votchStates.notVotched
        case votchStates.cancelPending:
            return votchStates.notVotched
        case votchStates.pendingVotch:
            return votchStates.notVotched
    
        default:
            break;
    }
}

export function currentVotchStateDefiner( isVotched, isPendeing ) {
    if(isPendeing) return votchStates.pendingVotch
    if(isVotched) return votchStates.unVotch
    if(!isVotched) return votchStates.notVotched
}

export function votchStateToUserData( currentState ) {
    // console.log('currentState', currentState)
    switch (currentState) {
        case votchStates.pendingVotch:
            return { is_votched: false, pending_votch: true}
        case votchStates.notVotched:
            return { is_votched: false, pending_votch: false}
        case votchStates.unVotch:
            return { is_votched: false, pending_votch: false}
    
        default:
            break;
    }
}