import { cloneElement } from "react";

export const isNotInBrowserEnvironment = typeof window === "undefined";

export function simpleDebouncer (fn, time = 400) {
  let timeout;
  return function (...args) {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
            timeout = null;
      fn.apply(this, args);
    }, time);
  }
}

export function getUrlParam (key) {
  if (isNotInBrowserEnvironment) return;
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key) || ''
}

export function setUrlParam (key, value) {
  const url = new URL(window.location);
  if (value) {
      url.searchParams.set(key, value);
  } else {
      url.searchParams.delete(key)
  }
  window.history.replaceState({}, '', url);
}

export function copytoClipboard(text) {
  let input = document.createElement('textarea');
  input.textContent = text;
  document.body.appendChild(input);
  input.select();
  const result = document.execCommand('copy');
  document.body.removeChild(input);
  return result;
}

export function jsonToFormdata ( jsonObject ) {
  const fd = new FormData();
  for (const [key,value] of Object.entries(jsonObject)) {
      fd.append(key, typeof value=== "object"? JSON.stringify(value): value)
  }
  return fd;
}

export function useQueryStringReader (locationSearch) {
  const result = deMilitrizedObject();
  new URLSearchParams(locationSearch || window.location.search).forEach((v,k) => result[k] = v);
  return result;
}

export function queryStringBuilder (obj) {
  const _query = {};
  for (const [k,v] of Object.entries(obj)) {
    v && (_query[k] = v);
  }
  return new URLSearchParams(_query)
}

export function sendGaEvent (category ='', action='', label='', value = 0, non_interaction = false) {
  try {
    // eslint-disable-next-line no-undef
    gtag('event', action, {
      event_label: label,
      value,
      event_category: category,
      non_interaction
    });
  } catch (error) {
    console.error('gtag not initialized properly', {
      category, action, label, value, non_interaction
    })
  }
}

export function rafRunner (callback, steps) {
  let counter = 0;
  let lastRafRef = {current: undefined};
  function iterator () {
    if( counter < steps ) {
      counter ++;
      callback();
      cancelAnimationFrame(lastRafRef.current);
      lastRafRef.current = requestAnimationFrame(iterator);
    }
  }
  iterator();
  return lastRafRef;
}

export function leadingEdgeInterval ( callback, timer ) {
  setTimeout(() => {
    callback()
  }, 0);
  return setInterval( () => {
    callback();
  }, timer)
}


export function route ( path ) {
  window.location.href = path;
}

export function redirectToLogout (  ) {
  route(`/auth?type=logout`)
}

export function redirectToLogin (  ) {
  
  if(window.location.pathname !== '/auth') {
    route(`/auth?next=${window.location.pathname + window.location.search}`)
  }
}

export function feedLinkGenerator ( { ballotId ='', type= 'all', userId= '', username='' } ) {
  return `/ballots?${queryStringBuilder(arguments[0])}`
}

export function profileLinkGenerator ( userObj ) {
  return `/u/${userObj?.id}`.replace('undefined', '')
}

export function userLinkGenerator ( userObj ) {
  return `${window.location.origin}/u/${userObj.id}`
}

export function ballotLinkGenerator ( id ) {
  return `${window.location.origin}/b/${id}`
}

export function ternary ( first, second ) {
  return first? first: second
}

export function cls(...classNames) {
  return classNames.join(' ')
}

export function randomRange(min, max) {
  return Math.random() * (max - min) + min;
}

export function randomNumber(length = 13) {
  return Math.random().toString().slice(2, length);
}

export async function appendScript(url) {
  return new Promise((resolve, reject) => {
    let sc = document.createElement("script");
    // sc.id = "googleApiLogin"
    sc.src = url;
    sc.defer = true;
    // sc.async = true;
    sc.onload = resolve;
    sc.onerror = reject;
    document.head.appendChild(sc);
  });
}

export function cdnLinkGenerator(id) {
  if( id?.includes('http') ) {
    return id
  }
  return `${process.env.REACT_APP_IMAGE_CDN_URL}/${id}`;
}

export function deMilitrizedObject() {
  return Object.create(null)
}

export function multiply(component, times) {
  return Array(times-0).map(index => cloneElement(component, { key: index}))
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function getLsItem(key) {
  if (isNotInBrowserEnvironment) return;
  if (key) {
    return JSON.parse(localStorage.getItem(key));
  }
}

export function persistThis(key, obj) {
  if (isNotInBrowserEnvironment) return;
  return localStorage.setItem(key, JSON.stringify(obj));
}

export function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const base64Meta = 'data:image/jpeg;base64,';
export function compressAndBase64(file, { quality = 100, fileOutput = false, scale } = {}) {
  if (typeof document !== "undefined") {
    return new Promise((res, rej)=>{
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = function() {
        URL.revokeObjectURL(file);
        const canvas = document.createElement('CANVAS');
        const ctx = canvas.getContext('2d');
        let scaleFactor
        if(file.size > 600 * 1000) { // 600kb
          scaleFactor = scale || 1.1**Math.log(file.size);
        } else {
          res(file); return;
        }
        canvas.height = this.naturalHeight / scaleFactor;
        canvas.width = this.naturalWidth / scaleFactor;
        ctx.scale(1/scaleFactor, 1/scaleFactor);
        ctx.drawImage(this, 0, 0);
        const a = r => {
          console.log(`${file.size} -> ${r.size}`)
          res(r)
        }
        fileOutput?
          canvas.toBlob(a,"image/jpeg", quality/100)
          :a(canvas.toDataURL("image/jpeg", quality/100))
      };
      img.onerror = rej;
    })
  }
}


export function toDataURL(file) {
  return new Promise( (res, rej) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      res(reader.result)
    }
    reader.onerror = rej;
    reader.readAsDataURL(file);
  })
}

export function toArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}
