import style from './style.module.css';

export default function BottomSheet ({open = true, onClose, children}) {
  return <div className={style.baseuo}>
    <div className={`${style.bottomSheet} ${open? style.slideIn : style.slideOut}`}>
      {open && children}
    </div>
    {open &&
      <div className={style.overlay} onClick={()=> {
        if( typeof onClose !== "function") {
          throw new Error("Onclose must be a function")
        }
        onClose()
      }} />
    }
  </div>
}

