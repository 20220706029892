// import style from './style.module.css';

import { cls } from "../../utils";

function BottomSheetRow({text, children, className}) {
  return <div className={cls("flex items-center", className)}>
    {children}
  </div>
}

export default BottomSheetRow;
