import { NavLink } from 'react-router-dom';
// import CaroFill from '../../assets/icons/view_carousel-24px-filled.svg'
// import CaroOutl from '../../assets/icons/view_carousel-24px.svg'
import Search from '../../assets/icons/search-24px.svg'
// import VoteFill from '../../assets/icons/how_to_vote-24px (1).svg'
// import VoteOutl from '../../assets/icons/how_to_vote-24px.svg'
import HomeFill from '../../assets/icons/home_black_24dp (1).svg'
import HomeOutl from '../../assets/icons/home_black_24dp.svg'
import AddFill from '../../assets/icons/add_circle-fill24px.svg'
import AddOutl from '../../assets/icons/add_circle_outline-24px.svg'
// import PeopleFill from '../../assets/icons/people-24px (1).svg'
// import PeopleOutl from '../../assets/icons/people-24px.svg'
import AccountFill from '../../assets/icons/account_box-24px.svg'
import AccountOutl from '../../assets/icons/account_box-24px (1).svg'
import NotifFill from '../../assets/icons/notifications-24px.svg'
import NotifOutl from '../../assets/icons/notifications_none-24px.svg'
// import SettingFill from '../../assets/icons/settings-24px (1).svg'
// import SettingOutl from '../../assets/icons/settings-24px.svg'
import style from './style.module.css';
import { connect } from 'react-redux';
import Icon from '../icon';


function BottomNav ({ unSeenNotifCount, username }) {
  return (
    <footer id={'bottomNav'} className={style.footer}>
      <nav>
        <NavLink activeClassName={style.active} className={style.bottomNavLink} to="/ballots">
          <Icon src={HomeOutl} className={style.outl} alt='' />
          <Icon src={HomeFill} className={style.fill} alt='' />
        </NavLink>
        {/* <Link activeClassName={style.active} className={style.bottomNavLink} to="/ballots">
          <Icon src={CaroOutl} className={style.outl} alt='' />
          <Icon src={CaroFill} className={style.fill} alt='' />
        </Link> */}
        {/* <Link activeClassName={style.active} className={style.bottomNavLink} to="/friends">
          <Icon src={PeopleOutl} className={style.outl} alt='' />
          <Icon src={PeopleFill} className={style.fill} alt='' />
        </Link> */}
        <NavLink activeClassName={style.active} className={style.bottomNavLink} to="/search">
          <Icon src={Search} className={style.outl} alt='' />
          <Icon src={Search} className={style.fill} alt='' />
        </NavLink>
        <NavLink activeClassName={style.active} className={style.bottomNavLink} to="/create">
          <Icon src={AddOutl} className={style.outl} alt='' />
          <Icon src={AddFill} className={style.fill} alt='' />
        </NavLink>
        <NavLink activeClassName={style.active} className={style.bottomNavLink} to="/notification">
          {(unSeenNotifCount > 0 ) && <span className={style.badge}>{unSeenNotifCount}</span>}
          <Icon src={NotifOutl} className={style.outl} alt='' />
          <Icon src={NotifFill} className={style.fill} alt='' />
        </NavLink>
        <NavLink activeClassName={style.active} className={style.bottomNavLink} to={`/profile`}>
          <Icon src={AccountOutl} className={style.outl} alt='' />
          <Icon src={AccountFill} className={style.fill} alt='' />
        </NavLink>
        {/* <Link activeClassName={style.active} className={style.bottomNavLink} to="/setting">
          <Icon src={SettingOutl} className={style.outl} alt='' />
          <Icon src={SettingFill} className={style.fill} alt='' />
        </Link> */}
      </nav>
    </footer>
  )
}

function s2p (state) {
  return {
    unSeenNotifCount: state.appMeta.notification?.unSeenNotifCount
  }
}

export default connect(s2p)(BottomNav);
