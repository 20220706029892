import Header from "../../components/header";
import Page from "../../components/page";

export default function Privacy() {
  return (
    <Page>
      <Header />

      <div style={{'font-size':'  16px', ' line-height':'  1.5', ' padding':'  0 8px', ' margin':'  auto', ' margin-top':'  40px', ' max-width':'  960px', ' text-alig': 'justify'}}>
        <div style={{'margin-bottom':'  60px', }}>
          This policy describes the information we process to support Imgress
          product and features offered by us.
        </div>
        <h2 style={{'font-size':'  22px', ' font-weight':'  normal', }}>
          <b>I. What kinds of information do we collect?</b>
        </h2>
        To provide the Imgress Product, we must process information about you.
        The types of information we collect depend on how you use our Product.
        You can learn how to access and delete information we collect by
        visiting the{" "}
        
          Imgress Settings
        
        and{" "}
        
          Imgress Settings
        
        .{" "}
        <blockquote>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  18px', ' line-height':'  inherit', }}
          >
            <b>Things you and others do and provide.</b>
          </div>
          
          <ul
            className="uiList _4of _4kg"
            style={{'list-style':'  disc', '  black; display':'  block', ' -webkit-margin-after':'  12px', ' -webkit-margin-before':'  12px', ' -webkit-margin-end':' 0px', ' -webkit-margin-start':'  0px', ' -webkit-padding-start':'  40px', }}
          >
            <li>
              <div className="fcb">
                <b>Information and content you provide.</b>
                We collect the content, communications and other information you
                provide when you use our Product, including when you sign up
                for an account, create or share content, and message or
                communicate with others. This can include information in or
                about the content you provide (like metadata), such as the
                location of a photo or the date a file was created. It can also
                include what you see through features we provide, such as our{" "}
                
                  camera
                
                , so we can do things like suggest masks and filters that you
                might like, or give you tips on using portrait mode. Our systems
                automatically process content and communications you and others
                provide to analyze context and what's in them for the purposes
                described{" "}
                
                  below
                
                . Learn more about how you can control who can see the things
                you{" "}
                
                  share
                
                .{" "}
                <ul
                  className="uiList _4of _4kg"
                  style={{'list-style':'  disc', '  black; display':'  block', ' -webkit-margin-after':'  12px', ' -webkit-margin-before':'  12px', ' -webkit-margin-end':' 0px', ' -webkit-margin-start':'  0px', ' -webkit-padding-start':'  40px', }}
                >
                  <li>
                    <div className="fcb">
                      Data with special protections: You can choose to provide
                      information in your Imgress{" "}
                      
                        profile fields
                      
                      or Life Events about your religious views, political
                      views, who you are "interested in," or your health. This
                      and other information (such as racial or ethnic origin,
                      philosophical beliefs or trade union membership) is
                      subject to special protections under EU law.
                    </div>
                  </li>
                </ul>
                <div className="mvm uiP fsm" />
              </div>
            </li>
            <li>
              <div className="fcb">
                <b>Networks and connections.</b>
                We collect information about the people,{" "}
                
                  Pages
                
                , accounts,{" "}
                
                  hashtags
                
                and groups you are connected to and how you interact with them
                across our Product, such as people you communicate with the
                most or groups you are part of. We also collect contact
                information if you{" "}
                
                  choose to upload, sync or import it from a device
                
                (such as an address book or call log or SMS log history), which
                we use for things like helping you and others find people you
                may know and for the other purposes listed{" "}
                
                  below
                
                .<div className="mvm uiP fsm" />
              </div>
            </li>
            <li>
              <div className="fcb">
                <b>Your usage.</b>
                We collect information about how you use our Product, such as
                the types of content you view or engage with; the features you
                use; the actions you take; the people or accounts you interact
                with; and the time, frequency and duration of your activities.
                For example, we log when you're using and have last used our
                Product, and what posts, videos and other content you view on
                our Product. We also collect information about how you use
                features like our camera.<div className="mvm uiP fsm" />
              </div>
            </li>
            <li>
              <div className="fcb">
                <b>Information about transactions made on our Product.</b>
                If you use our Product for{" "}
                
                  purchases
                
                or other financial transactions (such as when you make a
                purchase in a game or make a{" "}
                
                  donation
                
                ), we collect information about the purchase or transaction.
                This includes payment information, such as your credit or debit
                card number and other card information; other account and
                authentication information; and billing, shipping and contact
                details.<div className="mvm uiP fsm" />
              </div>
            </li>
            <li>
              <div className="fcb">
                <b>Things others do and information they provide about you.</b>
                We also receive and analyze content, communications and
                information that other people provide when they use our
                Product. This can include information about you, such as when
                others share or comment on a photo of you, send a message to
                you, or upload, sync or import your contact information.
              </div>
            </li>
          </ul>
          <br />
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  18px', ' line-height':'  inherit', }}
          >
            <b>Device Information</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            As described below, we collect information from and about the
            computers, phones, connected TVs and other web-connected devices you
            use that integrate with our Product, and we combine this
            information across different devices you use. For example, we use
            information collected about your use of our Product on your phone
            to better personalize the content (including{" "}
            
              ads
            
            ) or features you see when you use our Product on another device,
            such as your laptop or tablet, or to measure whether you took an
            action in response to an ad we showed you on your phone on a
            different device.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            Information we obtain from these devices includes:{" "}
            <ul
              className="uiList _4of _4kg"
              style={{'list-style':'  disc', '  black; display':'  block', ' -webkit-margin-after':'  12px', ' -webkit-margin-before':'  12px', ' -webkit-margin-end':' 0px', ' -webkit-margin-start':'  0px', ' -webkit-padding-start':'  40px', }}
            >
              <li>
                <div className="fcb">
                  <b>Device attributes:</b>
                  information such as the operating system, hardware and
                  software versions, battery level, signal strength, available
                  storage space, browser type, app and file names and types, and
                  plugins.<div className="mvm uiP fsm" />
                </div>
              </li>
              <li>
                <div className="fcb">
                  <b>Device operations:</b>
                  information about operations and behaviors performed on the
                  device, such as whether a window is foregrounded or
                  backgrounded, or mouse movements (which can help distinguish
                  humans from bots).<div className="mvm uiP fsm" />
                </div>
              </li>
              <li>
                <div className="fcb">
                  <b>Identifiers:</b>
                  unique identifiers, device IDs, and other identifiers, such as
                  from games, apps or accounts you use, and Family Device IDs
                  (or other identifiers unique to{" "}
                  
                    Imgress Company Product
                  
                  associated with the same device or account).
                  <div className="mvm uiP fsm" />
                </div>
              </li>
              <li>
                <div className="fcb">
                  <b>Device signals:</b>
                  Bluetooth signals, and information about nearby Wi-Fi access
                  points, beacons, and cell towers.
                  <div className="mvm uiP fsm" />
                </div>
              </li>
              <li>
                <div className="fcb">
                  <b>Data from device settings:</b>
                  information you allow us to receive through device settings
                  you turn on, such as access to your GPS location, camera or
                  photos.<div className="mvm uiP fsm" />
                </div>
              </li>
              <li>
                <div className="fcb">
                  <b>Network and connections:</b>
                  information such as the name of your mobile operator or ISP,
                  language, time zone, mobile phone number, IP address,
                  connection speed and, in some cases, information about other
                  devices that are nearby or on your network, so we can do
                  things like help you{" "}
                  
                    stream a video from your phone to your TV
                  
                  .<div className="mvm uiP fsm" />
                </div>
              </li>
              <li>
                <div className="fcb">
                  <b>Cookie data:</b>
                  data from cookies stored on your device, including cookie IDs
                  and settings. Learn more about how we use cookies in the{" "}
                  
                    Imgress Cookies Policy
                  
                  and{" "}
                  
                    Imgress Cookies Policy
                  
                  .
                </div>
              </li>
            </ul>
          </div>
          <br />
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  18px', ' line-height':'  inherit', }}
          >
            <b>Information from partners.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            Advertisers,{" "}
            
              app
            
            developers, and publishers can send us information through{" "}
            
              Imgress Business Tools
            
            they use, including our social plug-ins (such as the Like button),
            Imgress Login, our{" "}
            
              APIs and SDKs
            
            , or the Imgress{" "}
            
              pixel
            
            . These partners provide information about your activities off
            Imgress—including information about your device, websites you visit,
            purchases you make, the ads you see, and how you use their
            services—whether or not you have a Imgress account or are logged
            into Imgress. For example, a game developer could use our API to
            tell us what games you play, or a business could tell us about a
            purchase you made in its store. We also receive information about
            your online and offline actions and purchases from third-party data
            providers who have the rights to provide us with your information.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            Partners receive your data when you visit or use their services or
            through third parties they work with. We require each of these
            partners to have lawful rights to collect, use and share your data
            before providing any data to us.{" "}
            
              Learn more
            
            about the types of partners we receive data from.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            To learn more about how we use cookies in connection with Imgress
            Business Tools, review the{" "}
            
              Imgress Cookies Policy
            
            and{" "}
            
              Imgress Cookies Policy
            
            .
          </div>
          <br />
          <br />
        </blockquote>
        <h2 style={{'font-size':'  22px', ' font-weight':'  normal', }}>
          <b>II. How do we use this information?</b>
        </h2>
        
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          We use the information we have (subject to choices you make) as
          described below and to provide and support the Imgress Product and
          related services described in the{" "}
          
            Imgress Terms
          
          and{" "}
          
            Imgress Terms
          
          . Here's how:{" "}
        </div>
        <blockquote>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  18px', ' line-height':'  inherit', }}
          >
            <b>Provide, personalize and improve our Product.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We use the information we have to deliver our Product, including to
            personalize features and content (including your{" "}
            
              News Feed
            
            ,{" "}
            
              Imgress Feed
            
            , Imgress Stories and ads) and make suggestions for you (such as
            groups or{" "}
            
              events
            
            you may be interested in or topics you may want to follow) on and
            off our Product. To create personalized Product that are unique
            and relevant to you, we use your connections, preferences, interests
            and activities based on the data we collect and learn from you and
            others (including any{" "}
            
              data with special protections
            
            you choose to provide where you have given your explicit consent);
            how you use and interact with our Product; and the people, places,
            or things you're connected to and interested in on and off our
            Product. Learn more about how we use information about you to
            personalize your Imgress and Imgress experience, including features,
            content and recommendations in Imgress Product; you can also learn
            more about how we choose the{" "}
            
              ads
            
            that you see.{" "}
            <ul
              className="uiList _4of _4kg"
              style={{'list-style':'  disc', '  black; display':'  block', ' -webkit-margin-after':'  12px', ' -webkit-margin-before':'  12px', ' -webkit-margin-end':' 0px', ' -webkit-margin-start':'  0px', ' -webkit-padding-start':'  40px', }}
            >
              <li>
                <div className="fcb">
                  <b>Information across Imgress Product and devices:</b>
                  We connect information about your activities on different
                  Imgress Product and devices to provide a more tailored and
                  consistent experience on all Imgress Product you use,
                  wherever you use them. For example, we can suggest that you
                  join a group on Imgress that includes people you follow on
                  Imgress or communicate with using Messenger. We can also make
                  your experience more seamless, for example, by automatically
                  filling in your registration information (such as your phone
                  number) from one Imgress Product when you sign up for an
                  account on a different Product.<div className="mvm uiP fsm" />
                </div>
              </li>
              <li>
                <div className="fcb">
                  <b>Location-related information:</b>
                  We use{" "}
                  
                    location-related information
                  
                  -such as your current location, where you live, the places you
                  like to go, and the businesses and people you're near-to
                  provide, personalize and improve our Product,{" "}
                  
                    including ads
                  
                  , for you and others. Location-related information can be
                  based on things like precise device location (if you've
                  allowed us to collect it), IP addresses, and information from
                  your and others' use of Imgress Product (such as check-ins or
                  events you attend).<div className="mvm uiP fsm" />
                </div>
              </li>
              <li>
                <div className="fcb">
                  <b>Product research and development:</b>
                  We use the information we have to develop, test and improve
                  our Product, including by conducting surveys and research,
                  and testing and troubleshooting new Product and features.
                  <div className="mvm uiP fsm" />
                </div>
              </li>
              <li>
                <div className="fcb">
                  <b>Face recognition:</b>
                  If you have it turned on, we use face recognition technology
                  to recognize you in photos, videos and camera experiences. The
                  face-recognition templates we create are{" "}
                  
                    data with special protections
                  
                  under EU law. Learn more about{" "}
                  
                    how we use face recognition technology
                  
                  , or control our use of this technology in{" "}
                  
                    Imgress Settings
                  
                  . If we introduce face-recognition technology to your Imgress
                  experience, we will let you know first, and you will have
                  control over whether we use this technology for you.
                  <div className="mvm uiP fsm" />
                </div>
              </li>
              <li>
                <div className="fcb">
                  <b>Ads and other sponsored content:</b>
                  We use the information we have about you-including information
                  about your interests, actions and connections-to select and
                  personalize ads, offers and other sponsored content that we
                  show you. Learn more about how we{" "}
                  
                    select and personalize ads
                  
                  , and your choices over the data we use to select ads and
                  other sponsored content for you in the{" "}
                  
                    Imgress Settings
                  
                  and{" "}
                  
                    Imgress Settings
                  
                  .
                </div>
              </li>
            </ul>
          </div>
          <br />
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  18px', ' line-height':'  inherit', }}
          >
            <b>Provide measurement, analytics, and other business services.</b>
          </div>
          
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We use the information we have (including your activity off our
            Product, such as the websites you visit and ads you see) to help
            advertisers and other partners measure the effectiveness and
            distribution of their ads and services, and understand the types of
            people who use their services and how people interact with their
            websites, apps, and services.{" "}
            
              Learn how we share information
            
            with these partners.
          </div>
          <br />
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  18px', ' line-height':'  inherit', }}
          >
            <b>Promote safety, integrity and security.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We use the information we have to verify accounts and activity,
            combat harmful conduct, detect and prevent spam and other bad
            experiences, maintain the integrity of our Product, and promote
            safety and security on and off of Imgress Product. For example, we
            use data we have to investigate suspicious activity or violations of
            our terms or policies, or to{" "}
            
              detect when someone needs help
            
            . To learn more, visit the{" "}
            
              Imgress Security Help Center
            
            and{" "}
            
              Imgress Security Tips
            
            .
          </div>
          <br />
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  18px', ' line-height':'  inherit', }}
          >
            <b>Communicate with you.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We use the information we have to send you marketing communications,
            communicate with you about our Product, and let you know about our
            policies and terms. We also use your information to respond to you
            when you contact us.
          </div>
          <br />
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  18px', ' line-height':'  inherit', }}
          >
            <b>Research and innovate for social good.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We use the information we have (including from research partners we
            collaborate with) to conduct and support{" "}
            
              research
            
            and innovation on topics of general social welfare, technological
            advancement, public interest, health and well-being. For example,{" "}
            
              we analyze information we have about migration patterns during
              crises
            
            to aid relief efforts.{" "}
            
              Learn more
            
            about our research programs.
          </div>
          <br />
          <br />
        </blockquote>
        <h2 style={{'font-size':'  22px', ' font-weight':'  normal', }}>
          <b>III. How is this information shared?</b>
        </h2>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          Your information is shared with others in the following ways:
        </div>
        <div className="mvm uiP fsm" style={{'font-size':'  18px', ' line-height':'  inherit', }}>
          <b>Sharing on Imgress Product</b>
        </div>
        <blockquote>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>People and accounts you share and communicate with</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            When you share and communicate using our Product,{" "}
            
              you choose the audience for what you share
            
            . For example, when you post on Imgress, you select the audience for
            the post, such as a group, all of your friends, the public, or a
            customized list of people. Similarly, when you use Messenger or
            Imgress to communicate with people or businesses, those people and
            businesses can see the content you send. Your network can also see
            actions you have taken on our Product, including engagement with
            ads and sponsored content. We also let other accounts see who has
            viewed their Imgress or Imgress Stories.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>
              
                Public information
              
            </b>
            can be seen by anyone, on or off our Product, including if they
            don't have an account. This includes your Imgress username; any
            information you share with a public audience; information in your{" "}
            
              public profile on Imgress
            
            ; and content you share on a Imgress Page,{" "}
            
              public Imgress account
            
            or any other public forum, such as{" "}
            
              Imgress Marketplace
            
            . You, other people using Imgress and Imgress, and we can provide
            access to or send public information to anyone on or off our
            Product, including in other Imgress Company Product, in search
            results, or through tools and APIs. Public information can also be
            seen, accessed, reshared or downloaded through third-party services
            such as search engines, APIs, and offline media such as TV, and by
            apps, websites and other services that integrate with our Product.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            Learn more about what information is public and how to control your
            visibility on{" "}
            
              Imgress
            
            and{" "}
            
              Imgress
            
            .
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>Content others share or reshare about you</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            You should consider who you choose to share with, because people who
            can see your activity on our Product can choose to share it with
            others on and off our Product, including people and businesses
            outside the audience you shared with. For example, when you share a
            post or send a message to specific friends or accounts, they can
            download, screenshot, or reshare that content to others across or
            off our Product, in person or in virtual reality experiences such
            as{" "}
            
              Imgress Spaces
            
            . Also, when you comment on someone else's post or react to their
            content, your comment or reaction is visible to anyone who can see
            the other person's content, and that person can change the audience
            later.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            People can also use our Product to create and share content about
            you with the audience they choose. For example, people can share a
            photo of you in a Story, mention or tag you at a location in a post,
            or share information about you in their posts or messages. If you
            are uncomfortable with what others have shared about you on our
            Product, you can learn how to{" "}
            
              report the content
            
            .
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>
              Information about your active status or presence on our Product.
            </b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            People in your networks can see signals telling them whether you are
            active on our Product, including whether you are currently active
            on{" "}
            
              Imgress
            
            ,{" "}
            
              Messenger
            
            or Imgress, or when you last used our Product.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>
              Apps, websites, and third-party integrations on or using our
              Product.
            </b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            When you choose to use third-party apps, websites, or other services
            that use, or are integrated with, our Product, they can receive
            information about what you post or share. For example, when you play
            a game with your Imgress friends or use a Imgress Comment or Share
            button on a website, the game developer or website can receive
            information about your activities in the game or receive a comment
            or link that you share from the website on Imgress. Also, when you
            download or use such third-party services, they can access your
            public profile on Imgress, and any information that you share with
            them. Apps and websites you use may receive your list of Imgress
            friends if you choose to share it with them. But apps and websites
            you use will not be able to receive any other information about your
            Imgress friends from you, or information about any of your Imgress
            followers (although your friends and followers may, of course,
            choose to share this information themselves). Information collected
            by these third-party services is subject to their own terms and
            policies, not this one.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            Devices and operating systems providing native versions of Imgress
            and Imgress (i.e. where we have not developed our own first-party
            apps) will have access to all information you choose to share with
            them, including information your friends share with you, so they can
            provide our core functionality to you.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <i>
              Note: We are in the process of restricting developers’ data access
              even further to help prevent abuse. For example, we will remove
              developers' access to your Imgress and Imgress data if you haven't
              used their app in 3 months, and we are changing Login, so that in
              the next version, we will reduce the data that an app can request
              without app review to include only name, Imgress username and bio,
              profile photo and email address. Requesting any other data will
              require our approval.
            </i>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>New owner.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            If the ownership or control of all or part of our Product or their
            assets changes, we may transfer your information to the new owner.
          </div>
        </blockquote>
        <br />
        <div className="mvm uiP fsm" style={{'font-size':'  18px', ' line-height':'  inherit', }}>
          <b>Sharing with Third-Party Partners</b>
        </div>
        
        <blockquote>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We work with third-party partners who help us provide and improve
            our Product or who use Imgress Business Tools to grow their
            businesses, which makes it possible to operate our companies and
            provide free services to people around the world. We don't sell any
            of your information to anyone, and we never will. We also impose
            strict restrictions on how our partners can use and disclose the
            data we provide. Here are the types of third parties we share
            information with:
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>Partners who use our analytics services.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We provide aggregated statistics and insights that help people and
            businesses understand how people are engaging with their posts,
            listings, Pages, videos and other content on and off the Imgress
            Product. For example, Page admins and Imgress business profiles
            receive information about the number of people or accounts who
            viewed, reacted to, or commented on their posts, as well as
            aggregate demographic and other information that helps them
            understand interactions with their Page or account.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>Advertisers.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We provide advertisers with reports about the kinds of people seeing
            their ads and how their ads are performing, but we don't share
            information that personally identifies you (information such as your
            name or email address that by itself can be used to contact you or
            identifies who you are) unless you give us permission. For example,
            we provide general demographic and interest information to
            advertisers (for example, that an ad was seen by a woman between the
            ages of 25 and 34 who lives in Madrid and likes software
            engineering) to help them better understand their audience. We also
            confirm which Imgress ads led you to make a purchase or take an
            action with an advertiser.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>Measurement partners.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We share information about you with companies that aggregate it to
            provide analytics and measurement reports to our partners.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>Partners offering goods and services in our Product.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            When you subscribe to receive premium content, or buy something from
            a seller in our Product, the content creator or seller can receive
            your public information and other information you share with them,
            as well as the information needed to complete the transaction,
            including shipping and contact details.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>Vendors and service providers.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We provide information and content to vendors and service providers
            who support our business, such as by providing technical
            infrastructure services, analyzing how our Product are used,
            providing customer service, facilitating payments or conducting
            surveys.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>Researchers and academics.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We also provide information and content to research partners and{" "}
            
              academics
            
            to conduct research that advances scholarship and innovation that
            support our business or mission, and enhances discovery and
            innovation on topics of general social welfare, technological
            advancement, public interest, health and well-being.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            <b>Law enforcement or legal requests.</b>
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            We share information with law enforcement or in response to legal
            requests in the circumstances outlined below.
          </div>
          <div
            className="mvm uiP fsm"
            style={{'font-size':'  16px', ' line-height':'  inherit', }}
          >
            Learn more about how you can control the information about you that
            you or others share with third-party partners in the{" "}
            
              Imgress Settings
            
            and{" "}
            
              Imgress Settings
            
            .
          </div>
          <br />
          <br />
        </blockquote>
        <h2 style={{'font-size':'  22px', ' font-weight':'  normal', }}>
          <b>IV. How do the Imgress Companies work together?</b>
        </h2>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          Imgress and Imgress share infrastructure, systems and technology with
          other{" "}
          
            Imgress Companies
          
          (which include WhatsApp and Oculus) to provide an innovative,
          relevant, consistent and safe experience across all{" "}
          
            Imgress Company Product
          
          you use. We also process information about you across the Imgress
          Companies for these purposes, as permitted by applicable law and in
          accordance with their terms and policies. For example, we process
          information from WhatsApp about accounts sending spam on its service
          so we can take appropriate action against those accounts on Imgress,
          Imgress or Messenger. We also work to understand how people use and
          interact with Imgress Company Product, such as understanding the
          number of unique users on different Imgress Company Product.
        </div>
        <br />
        <br />
        <h2 style={{'font-size':'  22px', ' font-weight':'  normal', }}>
          <b>V. What is our legal basis for processing data?</b>
        </h2>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          We collect, use and share the data that we have in the ways described
          above:{" "}
          <ul
            className="uiList _4of _4kg"
            style={{'list-style':'  disc', '  black; display':'  block', ' -webkit-margin-after':'  12px', ' -webkit-margin-before':'  12px', ' -webkit-margin-end':' 0px', ' -webkit-margin-start':'  0px', ' -webkit-padding-start':'  40px', }}
          >
            <li>
              <div className="fcb">
                as necessary to fulfill our{" "}
                
                  Imgress Terms of Service
                
                or{" "}
                
                  Imgress Terms of Use
                
                ;
              </div>
            </li>
            <li>
              <div className="fcb">
                consistent with your consent, which you may revoke at any time
                through the{" "}
                
                  Imgress Settings
                
                and{" "}
                
                  Imgress Settings
                
                ;
              </div>
            </li>
            <li>
              <div className="fcb">
                as necessary to comply with our legal obligations
              </div>
            </li>
            <li>
              <div className="fcb">
                to protect your vital interests, or those of others;
              </div>
            </li>
            <li>
              <div className="fcb">as necessary in the public interest; and</div>
            </li>
            <li>
              <div className="fcb">
                as necessary for our (or others') legitimate interests,
                including our interests in providing an innovative,
                personalized, safe, and profitable service to our users and
                partners, unless those interests are overridden by your
                interests or fundamental rights and freedoms that require
                protection of personal data.
              </div>
            </li>
          </ul>
        </div>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          
            Learn more
          
          about these legal bases and how they relate to the ways in which we
          process data.{" "}
        </div>
        <br />
        <br />
        <h2 style={{'font-size':'  22px', ' font-weight':'  normal', }}>
          <b>VI. How can you exercise your rights provided under the GDPR?</b>
        </h2>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          Under the General Data Protection Regulation, you have the right to
          access, rectify, port and{" "}
          erase
          your data. Learn more about these rights, and find out how you can
          exercise your rights in the{" "}
          
            Imgress Settings
          
          and{" "}
          
            Imgress Settings
          
          . You also have the{" "}
          
            right to object to
          
          and restrict certain processing of your data. This includes:{" "}
          <ul
            className="uiList _4of _4kg"
            style={{'list-style':'  disc', '  black; display':'  block', ' -webkit-margin-after':'  12px', ' -webkit-margin-before':'  12px', ' -webkit-margin-end':' 0px', ' -webkit-margin-start':'  0px', ' -webkit-padding-start':'  40px', }}
          >
            <li>
              <div className="fcb">
                the right to object to our processing of your data for direct
                marketing, which you can exercise by using the "unsubscribe"
                link in such marketing communications; and
              </div>
            </li>
            <li>
              <div className="fcb">
                the right to object to our processing of your data where we are
                performing a task in the public interest or pursuing our
                legitimate interests or those of a third party. You can exercise
                this right on Imgress and on Imgress.
              </div>
            </li>
          </ul>
        </div>
        <br />
        <br />
        <h2 style={{'font-size':'  22px', ' font-weight':'  normal', }}>
          <b>VII. Data retention, account deactivation and deletion</b>
        </h2>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          We store data until it is no longer necessary to provide our services
          and Imgress Product, or until your account is deleted - whichever
          comes first. This is a case-by-case determination that depends on
          things like the nature of the data, why it is collected and processed,
          and relevant legal or operational retention needs. For example, when
          you search for something on Imgress, you can access and delete that
          query from within your search history at any time, but the log of that
          search is deleted after 6 months. If you submit a copy of your
          government-issued ID for account verification purposes, we delete that
          copy 30 days after review, unless otherwise stated. Learn more about
          deletion of{" "}
          
            content you have shared
          
          and{" "}
          
            cookie data obtained through social plugins
          
          .
        </div>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          When you delete your account, we{" "}
          
            delete things
          
          you have posted, such as your photos and status updates, and you won't
          be able to recover that information later. Information that others
          have shared about you isn't part of your account and won't be deleted.
          If you don't want to delete your account but want to temporarily stop
          using the Product, you can deactivate your account instead. To delete
          your account at any time, please visit the{" "}
          
            Imgress Settings
          
          and{" "}
          
            Imgress Settings
          
          .
        </div>
        <br />
        <br />
        <h2 style={{'font-size':'  22px', ' font-weight':'  normal', }}>
          <b>VIII. How do we respond to legal requests or prevent harm?</b>
        </h2>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          We access, preserve and share your information with regulators, law
          enforcement or others:{" "}
          <ul
            className="uiList _4of _4kg"
            style={{'list-style':'  disc', '  black; display':'  block', ' -webkit-margin-after':'  12px', ' -webkit-margin-before':'  12px', ' -webkit-margin-end':' 0px', ' -webkit-margin-start':'  0px', ' -webkit-padding-start':'  40px', }}
          >
            <li>
              <div className="fcb">
                In response to a legal request, if we have a good-faith belief
                that the law requires us to do so. We can also respond to legal
                requests when we have a good-faith belief that the response is
                required by law in that jurisdiction, affects users in that
                jurisdiction, and is consistent with internationally recognized
                standards.
              </div>
            </li>
            <li>
              <div className="fcb">
                When we have a good-faith belief it is necessary to: detect,
                prevent and address fraud, unauthorized use of the Product,
                violations of our terms or policies, or other harmful or illegal
                activity; to protect ourselves (including our rights, property
                or Product), you or others, including as part of investigations
                or regulatory inquiries; or to prevent death or imminent bodily
                harm. For example, if relevant, we provide information to and
                receive information from third-party partners about the
                reliability of your account to prevent fraud, abuse and other
                harmful activity on and off our Product.
              </div>
            </li>
          </ul>
          <div className="mvm uiP fsm" />
          Information we receive about you (including financial transaction data
          related to purchases made with Imgress) can be accessed and preserved
          for an extended period when it is the subject of a legal request or
          obligation, governmental investigation, or investigations of possible
          violations of our terms or policies, or otherwise to prevent harm. We
          also retain information from accounts disabled for terms violations
          for at least a year to prevent repeat abuse or other term violations.{" "}
        </div>
        <br />
        <br />
        <h2 style={{'font-size':'  22px', ' font-weight':'  normal', }}>
          <b>
            IX. How do we operate and transfer data as part of our global
            services?
          </b>
        </h2>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          We share information globally, both internally within the Imgress
          Companies and externally with our partners and with those you connect
          and share with around the world in accordance with this policy.
          Information controlled by Imgress Ireland will be transferred or
          transmitted to, or stored and processed in, the United States or other
          countries outside of where you live for the purposes as described in
          this policy. These data transfers are necessary to provide the
          services set forth in the{" "}
          
            Imgress Terms
          
          and{" "}
          
            Imgress Terms
          
          and to globally operate and provide our Product to you. We utilize{" "}
          
            standard contractual clauses
          
          approved by the European Commission and rely on the European
          Commission's{" "}
          
            adequacy decisions
          
          about certain countries, as applicable, for data transfers from the
          EEA to the United States and other countries.{" "}
        </div>
        <br />
        <br />
        <h2 style={{'font-size':'  22px', ' font-weight':'  normal', }}>
          <b>X. How will we notify you of changes to this policy?</b>
        </h2>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          We'll notify you before we make changes to this policy and give you
          the opportunity to review the revised policy before you choose to
          continue using our Product.{" "}
        </div>
        <br />
        <br />
        <h2 style={{'font-size':'  22px', ' font-weight':'  normal', }}>
          <b>XI. How to contact Imgress with questions</b>
        </h2>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          You can learn more about how privacy works{" "}
          
            on Imgress
          
          and on{" "}
          Imgress.
          If you have questions about this policy, you can contact us as
          described below.{" "}
        </div>
        <div className="mvm uiP fsm" style={{'font-size':'  16px', ' line-height':'  inherit', }}>
          The data controller responsible for your information is Imgress
          Ireland, which you can{" "}
          
            contact online
          
          , or by mail at: <br />
          <br />
          Imgress Ireland Ltd.
          <br />
          4 Grand Canal Square
          <br />
          Grand Canal Harbour
          <br />
          Dublin 2 Ireland <br />
          <br />
          
            Contact the Data Protection Officer for Imgress Ireland Ltd.
          
          <br />
          <br />
          You also have the right to lodge a complaint with Imgress Ireland's
          lead supervisory authority, the Irish Data Protection Commissioner, or
          your local supervisory authority.{" "}
        </div>
        <br />
        <br />
        Date of Last Revision: August 21, 2020 <br />
      </div>
    </Page>
  );
}
