import { useQuery } from 'react-query';
import { SingleBallotRowSkeleton } from '../../components/Skeleton';
import UserRow from '../../components/userRow';
import { multiply } from '../../utils';
import style from './style.module.css';
import { useApi, userList as userListApi } from '../../api'
import { Fragment } from 'react';
import Header from '../../components/header';
import Page from '../../components/page';
import { useParams } from 'react-router';


export default function UserList ({  votchers, votchees }) {
  const { id, name, count = 4 } = useParams();
  const userList = useQuery(
    [id, votchees, votchers],
    useApi(()=>userListApi(id, votchers))
  )
  
  return <Page>
    <Header text={name + (votchers? " followers": " following")} />
    <main className={style.userlist}> 
      {userList.data?.forbidden ? 
        <div>You Need to Follow {name} to view their details</div>
        :<Fragment>
          {userList.isFetching && <div>
            {multiply(<SingleBallotRowSkeleton />, count)}
          </div>}
          {userList.data?.length === 0 && <div>
            {name} has no {(votchers? " followers ": " following ")} yet
          </div>}
          {userList.data?.map?.((user,index)=><UserRow key={index} userObj={user} showVotchButton={votchers} />)}
      </Fragment>
      }
    </main>
  </Page>
}
