import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App, { history } from './components/app';
import reportWebVitals from './reportWebVitals';
import { auth0_client_id, auth0_domain, auth0_audience } from './utils/constants';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from 'react-query';
import './hammer';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
      staleTime: 1 * 60 * 1000
    },
    mutations: {
      retry: 2,
    }
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
          <Auth0Provider
              domain={auth0_domain}
              clientId={auth0_client_id}
              audience={auth0_audience}
              redirectUri={`${window.location.origin}/`}
              onRedirectCallback={(state)=>{
                  sessionStorage.setItem('login-state', state?.returnTo || window.location.pathname);
                  history.replace('/login');
              }}
              useRefreshTokens={true}
              cacheLocation={"localstorage"}
          >
              <App />
          </Auth0Provider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
