
import style from './style.module.css';

export default function Spinner({size = 25, thickness = (size/25).toFixed(1), className = ''}) {
    return (
        <div 
            className={`${style.loader} ${className}`}
            style={{
                '--loading-width': `${size}px`,
                '--loading-height': `${size}px`,
                '--loading-stroke-width': `${thickness}px`
            }}
        ></div>
    )
}
