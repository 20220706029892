// import { Fragment, useEffect, useState } from 'react';
import style from './style.module.css'
import Button from '../button'
import Seen from '../../assets/icons/priority_high_black_24dp.svg';
import { cdnLinkGenerator, cls, profileLinkGenerator, sendGaEvent } from '../../utils';
import Icon from '../icon';
import { Link } from 'react-router-dom';

export default function UserRow({ userObj, type }) {
  const isVotchRequestType = type === 'votchRequest';
  const isNotifType = type === 'notif';
  const isVoteType = type === 'vote';
  return (
    <div className={style.cardContainer} >
      <Link to={profileLinkGenerator(userObj)} className={style.linkSection}>
        <img src={cdnLinkGenerator(userObj.picture)} className={style.cardImg} loading='lazy' alt='' />
        <div className={style.textWrapperss}>
          <div>{userObj.name}</div>
          { !isVotchRequestType &&
            <div>
              {userObj.username}
            </div>
          }
        </div>
        
      </Link>
      { isNotifType &&
        <a href={(userObj.redirect)} className='flex justify-end right-align pr1 bold'> 
          {userObj.type.replace('-', ' ')}
        </a>
      } 
      { isVoteType &&
        <img src={cdnLinkGenerator(userObj.vote)} className={cls(style.voteimg, 'p1')} />
      }
      {isVotchRequestType && 
        <div className='flex'>
          <Button color='pr-outline' className='my22 mr1' onClick={(e)=>{
            // eslint-disable-next-line no-restricted-globals
            if(confirm('Confirm removing request?')) {
              userObj.ignore()
              sendGaEvent('General', `Follow request ignored`);
              e.target.textContent = ' ...  '
            } 
          }}>X</Button>
          <Button color='primary' className='my22' onClick={(e)=>{
            e.target.textContent = ' ...  '
            userObj.confirm();
            sendGaEvent('General', `Follow request confirmed`);
          }}>✔</Button>
        </div>
      }
      {/* {isFollowType &&
        <Button color='primary' onClick={(e)=>{
          e.target.textContent = ' ...  '
          userObj.confirm()
        }}>Follow</Button>
      } */}
      {(!userObj.seen && isNotifType ) && <Icon src={Seen} dark />}
    </div>
  )
}

