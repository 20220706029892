import { memo, useEffect, useRef, useState } from 'react';
import { cls } from '../../utils';
import style from './style.module.css'
import CheckIcon from '../../assets/icons/check_circle-24px.svg'
// import FaivoriteIcon from '../../assets/icons/favorite_black_24dp.svg'
import Skeleton from '../Skeleton'
import Icon from '../icon';


function ImageLazyPreview({ voteInfo: {percent, voteCount, show} , mainImageLink, gridClass, loading, selected, onClick}) {
  const idRef = useRef();
  const [showCheck, setShowCheck] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = mainImageLink;
    img.onload = ()=>setIsLoaded(true);
    return () => {
      img.remove();
    }
  }, []);
  useEffect(() => {
    if(selected) {
      idRef.current = setTimeout(() => {
        setShowCheck(false)
      }, 1500);
      setShowCheck(true)
    }
  }, [selected]);
  useEffect(() => () => clearTimeout(idRef.current), [])
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div 
      onDoubleClick={onClick}
      className={cls(
        style.container, 
        selected?
          loading? 
            style.loading
            :style.selected
          : ''
      )} 
      style={{gridArea: gridClass}}
    >
      {isLoaded?
        <img 
          className={`${style.thumbnail} ${style.selected} ${isLoaded && style.undoBlur }`} 
          src={mainImageLink} 
        />
        :<Skeleton width={window.innerWidth/2} height={150} />
      }
      {show && <div className={style.statsContainer}>
          {Number.isFinite(percent*1) && <span className={style.voteNumber}>{percent}%</span>}
          <span>{voteCount} vote(s)</span>
        </div>
      }
      {<Icon src={CheckIcon} className={cls(style.check, showCheck && style.fadeToExistense)} />}
    </div>
  )
}

export default memo(ImageLazyPreview)

