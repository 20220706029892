import { cls } from '../../utils';
import { themeColors, themes } from '../../utils/constants';
import style from './style.module.css';

function Icon({src, dark, light, className, ...rest}) {
  return <img 
  src={src} 
  alt='' 
  style={{
    filter: (dark || light) && themeColors[(dark && themes.dark) || themes.light].svgDefault}}
  {...rest} 
  className={cls(style.svgB, className, )} 
  />
}

export default Icon;
