/* eslint-disable react-hooks/rules-of-hooks */
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useRef } from 'react';
import { useInfiniteQuery } from 'react-query';
import { ballotsList, useApi } from '../../api';
import Button from '../../components/button';
import { SingleBallotPageSkeleton } from '../../components/Skeleton';
import { cls, sendGaEvent, useQueryStringReader } from '../../utils';
import SingleBallot from '../singleBallot';
import style from './style.module.css';

function toView(id, options={}) {
  document.getElementById(id).scrollIntoView(options);
}

function usePagination(key, fetchCallback) {
  const infiniteQuery = useInfiniteQuery(
    key, 
    useApi(fetchCallback), 
    {
      getPreviousPageParam: (lastPage, pages) => lastPage?.before?.[0]? 
        ['before', lastPage?.before?.[0]]: undefined,
      getNextPageParam: (lastPage, pages) => lastPage?.after?.[0]?
        ['after', lastPage?.after?.[0]]: undefined
    }
    );
    return {
      infiniteQuery
    }
  }
  
  

function Ballots () {
  const queryParams = useMemo(() => useQueryStringReader(), []);
  const {type = 'all', userId, ballotId, name='', tagName} = queryParams;
  const latestPageDirection = useRef({});
  const latestSeenBallot = useRef();
  const prevBallotLength = useRef();
  const onlyOnce = useRef(true);
  const onlyOnce2 = useRef(true);
  
  function fetchBallots ({ pageParam }) { 
    if(onlyOnce.current) {
      pageParam = ['after', ballotId || 'feed'];
      onlyOnce.current = false;
    }
    try {
      if(pageParam[1] === 'feed') pageParam[1] = undefined;
      latestSeenBallot.current = pageParam[1];
      return ballotsList(pageParam, type, userId || tagName)
    } catch (error) {
      onlyOnce.current = true;
    }
  }
  const { infiniteQuery } = usePagination(['ballots', type, userId, ballotId], fetchBallots);
  const ballots = infiniteQuery?.data?.pages?.map(page => page?.data).flat(1) || [];

  const io = useRef(new IntersectionObserver(((entries) => {
    entries.forEach( entry => {
      if(entry.isIntersecting){
        if(entry.target.id ==='prev') {
          infiniteQuery.fetchPreviousPage();
          latestPageDirection.current = 'prev'
        } else {
          infiniteQuery.fetchNextPage();
          latestPageDirection.current = 'next'
        }
      }
    })
  }), { threshold: .5}));

  useEffect(() => {
    sendGaEvent('Feed', `Feed type ${  type  } viewed`)
  }, [type]);

  useEffect(() => {
    if (infiniteQuery.isSuccess && onlyOnce2.current) {
      if (ballotId) {
        toView(ballotId,{block: 'center'})
      }
      onlyOnce2.current = false;
    }
    if (infiniteQuery.hasPreviousPage) {
      io.current?.observe(document.getElementById('prev'))
    }
    if (infiniteQuery.hasNextPage) {
      io.current?.observe(document.getElementById('next'))
    }
  }, [infiniteQuery.isLoading]);

  //? io clean up
  useEffect(()=>()=>io.current.disconnect(),[]);

  useEffect(()=>{
    let id;
    if (
      prevBallotLength.current && 
      (prevBallotLength.current !== ballots.length) && 
      (ballots.length > 0) &&
      (latestPageDirection.current=== 'prev') && 
      infiniteQuery.isFetched
    ) {
      id = ballots[ballots.findIndex(id=>id===latestSeenBallot.current) ];
      latestPageDirection.current =  undefined;
    }
    prevBallotLength.current = ballots.length;
    if(id) toView(id);
  },[ballots.length])

  return(
    <div className={style.home}>
      {(infiniteQuery.isLoading)?
        <div className={cls(style.page, 'pt3 px1')}>
          <SingleBallotPageSkeleton />
        </div>
        :
        <main className={style.list}>
          {name || tagName ?
            <h2 className={cls(style.h2,'flex justify-between h2 px2 py2 mellowBorderBottom')}>{
              `${name || '#'+tagName }  polls`}</h2>
            :<h2 className={cls(style.h2, 'h3 px2 py2 mellowBorderBottom')}>
              Double Tap the better Image to vote
            </h2>
          }
          {!infiniteQuery.isLoading && ballots.length === 0 &&
            <div className={style.page}>
              <div className={style.noBallotsContainer}>
                <p>There are no {type} ballots for you at this moment 👏</p>
                <p className="flex flex-column justify-center mt3">
                  Check out All public posts
                  <Link to={'/base'}><Button>Explore other ballots</Button></Link>
                </p>
              </div>
            </div>
          }
          {
            infiniteQuery.hasPreviousPage && <div id='prev'><SingleBallotPageSkeleton /></div>
          }
          {ballots.map((id) => {
            return id && (
              <div key={id} id={id} className={style.pagobjecte}>
                <SingleBallot id={id} readFromCache={true} type={type} />
              </div>
          )})}
          {
            infiniteQuery.hasNextPage && <div id='next'><SingleBallotPageSkeleton /></div>
          }
        </main>
      }
    </div>
  )
}

function Tag ({url , text, selected}) {
  return (
    <Link to={url}>
      <Button 
        style={{borderRadius: '12px', padding: '3px 6px'}} 
        color={selected? 'primary': 'pr-outline-trans'}
      >{text}</Button>
    </Link>
  )
}

export default Ballots;
