import PollingRound from '../../assets/icons/poll-24px-logo.svg'
import Icon from "../icon";
import { release_verison } from "../../utils/constants";
import { cls } from "../../utils";
import style from './style.module.css';

function Splash({rotating}) {
  return (
    <div className={style.splash}>
      <Icon src={PollingRound} className={cls(style.LogoSplash, rotating && style.rotating)} dark />
      <p className={style.ppp}>Imgress {release_verison}</p>
    </div>
  );
}
export default Splash