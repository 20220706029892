/* eslint-disable react-hooks/rules-of-hooks */
import { useState, memo, Fragment } from 'react';
import style from './style.module.css';
import Spinner from '../../components/spinner';
import Icon from '../../components/icon';
// import Hider from '../../components/hider';
import ImageLazyPreview from '../../components/lazyImage';
import { ballotLinkGenerator, cdnLinkGenerator, cls, copytoClipboard, deepCopy, sendGaEvent, profileLinkGenerator } from '../../utils';
import ShareIcon from '../../assets/icons/share-24px.svg'
import MoreVert from '../../assets/icons/more_vert-24px.svg'
import GlobeIcon from '../../assets/icons/public-24px (1).svg'
import LockIcon from '../../assets/icons/lock-24px.svg'
import Launch from '../../assets/icons/launch_black_24dp.svg'
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { SingleBallotPageSkeleton } from '../../components/Skeleton';
import Button from '../../components/button';
import { useSelector } from 'react-redux';
import { votchUser, ballotDelete, ballotDetails, ballotVote, anonymousVoteOnBallot, voteOnBallot, ballotVoteDetails, anonymousBallotDetails, useApi } from '../../api'
import BottomSheet from '../../components/bottomSheet';
import { formatDistanceToNow } from 'date-fns'
import UserRow from '../../components/userRow';
import VoteTutorial from '../../components/voteTutorial';
import { Link, useParams } from 'react-router-dom';
import BottomSheetRow from '../../components/bottomSheetRow';
import { showToast } from '../../components/app';
import { toastTypes } from '../../utils/constants';
import { TagChip } from '../search';


export default memo(({ id: propId, readFromCache, type }) => {
  const { id: queryParamId } = useParams();
  const id = propId || queryParamId;
  const isOnSlashB = window.location.pathname.startsWith('/b/');
  const isAnonymous = type === 'featured';
  const [bottomSheet, setBottomSheet] = useState(false);
  const queryClient = useQueryClient();
  const loggedUser = useSelector(state => state.appMeta.user);
  const [showVotes, setShowVotes] = useState(false);
  const voteQuery = useQuery(
    ['ballot', 'vote', id], 
    useApi(() => ballotVote(id)),
    {
      enabled: showVotes,
    }
  );

  const ballotKey = ['ballot', id]
  const ballotQuery = useQuery(
    ballotKey, 
    useApi(() => isAnonymous?
      anonymousBallotDetails(id)
      :ballotDetails(id)
    ),
    { 
      keepPreviousData: true, 
      refetchOnMount: !readFromCache,
      refetchOnReconnect: !readFromCache,
      staleTime: 5 * 60 * 1000
      // refetchOnReconnect: !noRefetch,
      // enabled: !noRefetch
    }
  );
  const ballotVotesQuery = useQuery(
    [ballotKey, 'votes'], 
    useApi(() => ballotVoteDetails(id)),
    { keepPreviousData: true, enabled: showVotes && !isAnonymous }
  );
  const isOwner = loggedUser.id === ballotQuery?.data?.owner?.id;
  const votes = voteQuery?.data || [];
  const ballot = ballotQuery?.data || {};
  const deleteMutation = useMutation(useApi(() => ballotDelete(ballot.id)), {
    async onMutate (imgId) {

    },
    onSuccess: () => {
      if(isOnSlashB) {
        window.history.back()
      }
      showToast('Ballot deleted', toastTypes.success)
      sendGaEvent('Single Ballot page', 'Ballot deleted', `Deleted Ballot id: ${id}`);
      queryClient.invalidateQueries([loggedUser.id, ballot.is_public? 'ballots':'privateBallots']);
      queryClient.invalidateQueries(['ballots', 'user', loggedUser.id, id]);
      queryClient.invalidateQueries(['ballots', 'all', '', ''])
    },
    onError: () => {
      sendGaEvent('Single Ballot page', 'Ballot deletion unsuccessful', `Unsuccessful Delete Ballot id: ${id}`);
      showToast('Ballot deleted wasn\'t successful', toastTypes.error)
    },
  });
  const voteMutation = useMutation(
    useApi((imgId)=> isAnonymous?
      anonymousVoteOnBallot(ballot?.vote, ballot.id, imgId)
      :voteOnBallot(ballot?.vote, ballot.id, imgId)), 
    {
    async onMutate (imgId) {
      await queryClient.cancelQueries(ballotKey);
      const existingBallot = queryClient.getQueryData(ballotKey);
      const updatedBallot = deepCopy(existingBallot);
      updatedBallot.vote = imgId;
      queryClient.setQueryData(ballotKey, updatedBallot);
      const mutateReturnValue = {
        existingBallot,
        updatedBallot
      };
      return mutateReturnValue
    },
    onSuccess: (response, imgId, { existingBallot, updatedBallot }) => {
      sendGaEvent('Single Ballot page', 'Voted on ballot', `Vote on Ballot id: ${id}`);
      queryClient.invalidateQueries(['ballot', 'vote', id])
    },
    onError: (response, imgId, { existingBallot, updatedBallot }) => {
      sendGaEvent('Single Ballot page', 'Vote on ballot failed', `Failed to vote Ballot id: ${id}`);
      showToast('Voting on this ballot wasn\'t successful', toastTypes.error)
      queryClient.setQueryData(ballotKey, existingBallot);
    },
  });
  const stats = {};
  votes?.forEach((el)=>{
    if (!stats[el]) stats[el] = 0;
    stats[el] += 1
  })
  const ballotAccessAuthorized = ballot.isAuthorized || isAnonymous;
  //! 404
  if(ballotQuery.error) return <div className={style.err}>Error during fetching Ballot</div>;
  if( !ballotQuery.isLoading && ballot.ownerDeactived ) {
    return <div className={style.err}>
      Ballot owner has deactivated their account
      <br />
      swipe to next →
    </div>
  }

  return (
    <div className={cls(style.pageWrapper, isOnSlashB && style.fullHeight2)}>
      {ballotQuery.isLoading? 
        <div className={style.skeletonWrapper}><SingleBallotPageSkeleton /></div>
        :
        <Fragment >           
        {!isAnonymous &&
          <header className={style.header}>
            <Link to={profileLinkGenerator(ballot?.owner)} className={style.userLinkWrapper}>
              <img className={style.avatar} src={cdnLinkGenerator(ballot?.owner?.picture)} alt="profile image" />
              <div className={style.userinfo}>
                <h3>{ballot?.owner?.username}</h3>
                <sub>{ballot?.owner?.name}</sub>
              </div>
            </Link>
            <span className='ml-auto' />
            {!isOnSlashB && <Link to={`/b/${id}`} style={{opacity:.75, transform: 'scale(.9)'}}><Icon src={Launch} /></Link>}
            <button onClick={()=>{
              setBottomSheet(true);
              sendGaEvent('Single Ballot page', 'More options clicked on ballots', `More options click on Ballot id: ${id}`);
            }} className={style.more}><Icon src={MoreVert} /></button>
          </header>
          }
          {ballotAccessAuthorized? 
            <main className={style.ballotContainer}>
              <VoteTutorial />
              <div className={style.imagesContainer}>
                {ballot?.images?.map( (imgObj, i) => 
                  <ImageLazyPreview 
                    key={i}
                    voteInfo = {{
                      percent: ((stats[imgObj.id]/ballot.totalVotes)*100).toFixed(0),
                      voteCount: stats[imgObj.id] || 0,
                      show: showVotes && !voteQuery.isFetching
                    }}
                    gridclassName={`i${i}`}
                    mainImageLink={isAnonymous?
                      imgObj.url
                      :cdnLinkGenerator(imgObj.id)
                    }
                    loading={loggedUser.id && voteMutation.isLoading}
                    selected={loggedUser.id && (ballot.vote === imgObj.id)}
                    onClick={()=>{
                      if(ballot.vote !== imgObj.id) {
                        voteMutation.mutate(imgObj.id)
                        setShowVotes(false)
                      }
                    }}
                  />
                )}
              </div>
              {(ballot.description) && <p className={cls(style.description, 'h5')}>{ballot.description}</p>}
              <div className={style.stats}>
                <i className={style.imgIcon}>
                  <Icon src={!ballot.is_public? LockIcon :GlobeIcon} />
                  {/* {ballot.is_public? 'Public' :'Private'} */}
                </i>
                <span className={style.deadline}>
                  <span className='h5'>
                    {ballot?.created_at?.["@ts"] && (
                      `${formatDistanceToNow(new Date(ballot?.created_at?.["@ts"]))  } ago`
                    )}
                  </span>
                  <span className='h5'>Total votes: <span className='h4'>{ballot.totalVotes}</span></span>
                </span>
                { ballot?.vote?
                  <Button color='link' className={style.showmorebutton} onClick={()=>{
                    setShowVotes(!showVotes);
                    sendGaEvent('Single Ballot page', 'View ballot results clicked', `View result for Ballot id: ${id}`);
                  }}>
                    {voteQuery.isFetching ? 
                      <span><Spinner size='10' /></span>
                      :<span>{showVotes? 'Hide result' : 'View result' }</span>
                    }
                  </Button>
                  :<span className='h5'>Vote to view results</span>
                }
              </div>
              {
                  isOnSlashB && 
                  <div className='mt3 px2'>
                    <h3 className='mb2'>Hashtags:</h3>
                    <div className='flex flex-wrap center' style={{maxWidth: '90vw'}}>
                      {ballot?.all_images_tags?.map(tag => <TagChip tag={tag} />)}
                    </div>
                  </div>
                }
              {(showVotes && !ballotVotesQuery.data?.forbidden) && <div className={style.voteDetails}>
                {ballotVotesQuery.isLoading && <div className='center'><Spinner size='25' /></div>}
                  <div>
                    <p className='px2 py1 h5' style={{opacity:.85}}>Voters details are only visible to ballot owner and thier followers</p>
                    {ballotVotesQuery.data?.map?.((user, i)=><UserRow userObj={user} type="vote" key={i} />)}
                  </div>
                </div>
              }
            </main>
            :<main className={style.unathorized}>
              <Icon src={LockIcon} />
              <br />
              You need to 
              <Button color='pr-outline' className='my2' onClick={(event)=>{
                const thisArg = event.target;
                if(loggedUser?.id) {
                  thisArg.textContent = ' ... Sending request ...'
                }
                sendGaEvent('Single Ballot page', 'Follow request sent from ballot', `Follow request sent from Ballot id: ${id}`);
                votchUser(ballot?.owner?.id).then(res=>{
                  thisArg.textContent = 'Follow request sent!'
                })
              }}>Follow {ballot?.owner?.name}</Button> 
              to view their private ballots
            </main>
            }
        </Fragment>
      }
      <BottomSheet open={bottomSheet} onClose={()=>setBottomSheet(false)}>
        <BottomSheetRow >
          <Button onClick={function(event){
            copytoClipboard(ballotLinkGenerator(ballot.id))
            event.target.textContent = 'Link Copied!'
          }} className="flex items-center">
            <Icon src={ShareIcon} />
            {'   Copy ballot Link'}
          </Button>
        </BottomSheetRow>
          {isOwner ?
            <BottomSheetRow>
              <Button color="red" onClick={()=>{
                const isSure = window.confirm('Do You confirm Deleting the Ballot?')
                if(isSure) deleteMutation.mutate()
              }}>
                {deleteMutation.isLoading ? 
                  <Spinner size='10' />
                  :<span>Delete ballot</span>
                }
              </Button>
            </BottomSheetRow>
            :
              <BottomSheetRow >
                <Button color='red' onClick={function(event){
                  event.target.textContent = 'Feature not available yet';
                  sendGaEvent('Single Ballot page', 'Ballot reported', `reported Ballot id: ${id}`);
                }} className="flex items-center">
                  Report
                </Button>
              </BottomSheetRow>
          }
          {isOnSlashB && <div className='mt3' />}
      </BottomSheet>
    </div>
  );
},()=>true)