import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import { cdnLinkGenerator } from '../../utils';
import style from './style.module.css'

export default function SingleBallotRow({ ballot, user={} }) {
  return (
    <Link to={`/ballots?ballotId=${ballot?.id}&userId=${user.id}&name=${user.name}&type=user`} className={style.cardContainer}>
      <div className={style.imagesContainer}>
        {ballot.images.map(imgObj => <img 
            key={imgObj.id}
            src={cdnLinkGenerator(imgObj.id)} 
            className={style.cardImg}
            alt=''
            />
        )}
      </div>
      <div>
        {(ballot?.ends_in?.["@ts"] || ballot?.created_at?.["@ts"]) && `${formatDistanceToNow(
          new Date(ballot?.created_at?.["@ts"] || ballot?.ends_in?.["@ts"]) )  } ago`
        }
      </div>
    </Link>
  )
}

