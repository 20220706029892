import { configureStore } from "@reduxjs/toolkit";
import { uploadSlice } from "./create";
import { appMetaSlice, setUnseenNotifCount } from "./appMeta";
import { searchSlice } from "./search";


export const store = configureStore({
  reducer: {
    upload: uploadSlice.reducer,
    search: searchSlice.reducer,
    appMeta: appMetaSlice.reducer
  },
});

try {
  const channel = new BroadcastChannel('sw-messages');
  channel.addEventListener('message', event => {
    console.log('Received', event.data);
    store.dispatch(setUnseenNotifCount(1));
  });
} catch (error) {
  
}



// window.addEventListener('message', (event) => {
//   try {
//     const messageObject = JSON.parse(event.data);
//     if (messageObject.source === 'service-worker') {
//       store.dispatch(setUnseenNotifCount(1));
//     }
//   } catch (error) {
//     // console.log(error)
//   }
// })
