/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { votchRequestList, notifList, votchConfirm, votchIgnore, notifsSeen, useApi } from '../../api';
import Button from '../../components/button';
import Page from '../../components/page';
import Spinner from '../../components/spinner';
import UserRow from '../../components/userRow';
import { setUnseenNotifCount, setShowNotification } from '../../redux/appMeta';
import { pushNotifStates } from '../../utils/constants';

const pushNotifSupported = ('serviceWorker' in navigator) && ('PushManager' in window);

export default function Notification () {
  const dispatch = useDispatch();
  const showNotification = useSelector(state => state.appMeta.notification.notificationActivationState);
  const votchRequestListQuery = useQuery('votchRequestsList', useApi(votchRequestList));
  const notifsListQuery = useQuery('notifsList', useApi(notifList));
  // const notifsCountQuery = useQuery('notifcount', notifCount);
  const notifSeenMutation = useMutation(useApi(notifsSeen));
  const votchRequestConfirmMutation = useMutation(useApi(votchConfirm));
  const votchRequestIgnoreMutation = useMutation(useApi(votchIgnore));

  useEffect(() => {
    if (!notifsListQuery.isLoading && notifsListQuery.data?.some(obj => !obj.seen)) {
      notifSeenMutation.mutate();
      dispatch(setUnseenNotifCount(0));
    }
  }, [notifsListQuery.isLoading]);

  return (
    <Page>
      <main className='px2'>
        <h2 className='h2 pt2 pb3'>Follow Requests</h2>
        {votchRequestListQuery.isFetching && <div className='center'><Spinner size='50' /></div>}
        {!votchRequestListQuery.isFetching && votchRequestListQuery.data?.length === 0 && 
          <div className='center'>
            No new follow Requests.
          </div>
        }
        {votchRequestListQuery.data?.map(obj =>
          <UserRow 
            type='votchRequest' 
            key={obj.id}
            userObj={{
              id: obj.id,
              name: obj.name,
              picture: obj.picture,
              username: obj.username,
              confirm: ()=>{
                votchRequestConfirmMutation.mutateAsync(obj.id).then(r=>{
                  votchRequestListQuery.refetch()
                })
              },
              ignore: ()=>{
                votchRequestIgnoreMutation.mutateAsync(obj.id).then(r => {
                  votchRequestListQuery.refetch()
                })
              }
            }}
          />
        )}
        { pushNotifSupported && !showNotification && 
          <Button color='primary' className='mt3' style={{width: '100%'}}
            onClick={()=>{
              dispatch(setShowNotification(pushNotifStates.allowedByUser))
            }}
          >
            Enable Push notifications 🔔?
          </Button>
        }
        <h2 className='h2 py3'>Notifications</h2>
        {notifsListQuery.isFetching && <div className='center'><Spinner size='50' /></div>}
        {!notifsListQuery.isFetching && notifsListQuery.data?.length === 0 && 
          <div className='center'>
            No new Notifications.
          </div>
        }
        {notifsListQuery.data?.map((obj, index) =>
          <UserRow 
            type='notif' 
            key={index}
            userObj={{
              id: obj.user.id,
              name: obj.user.name,
              picture: obj.user.picture,
              username: obj.user.username,
              type: obj.type,
              redirect: obj.redirect,
              seen: obj.seen
            }}
        />)}
      </main>
    </Page>
  );
}

