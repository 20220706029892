import { createSlice } from "@reduxjs/toolkit";
import { sendGaEvent } from "../utils";

const initaialState = {
    selectedImages:[],
};

export const searchSlice = createSlice({
    name: 'search',
    initialState: initaialState,
    reducers: {
        resetImage(state, action) {
            state.selectedImages = [];
        },
        selectImage(state, action) {
            state.selectedImages.push(action.payload)
        },
        unSelectImage(state, action) {
            state.selectedImages = state.selectedImages.filter(img => img.id !== action.payload.id)
        },
        saveSearchterm(state, action) {
            state.term = action.payload;
        },
    }
})

export const { 
    selectImage,
    unSelectImage,
    resetImage,
    saveSearchterm
} = searchSlice.actions
