import { cls } from '../../utils';
import style from './style.module.css'

function Page({children, classNames, ...rest}) {
  return (
    <div className={cls(style.defaultPageStyle, classNames)} {...rest}>
      {children}
    </div>
  )
}
export default Page;