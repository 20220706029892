import { useEffect } from "react"
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { getLsItem, persistThis, urlBase64ToUint8Array } from ".";
import { savePushSub, useApi } from "../api"
import { setShowNotification } from "../redux/appMeta";
import { store } from "../redux/store";
import { pushNotifStates } from "./constants";


const publicVAPIDkey = process.env.REACT_APP_VAPID_PUBLIC;
const deviceID = MediaDeviceInfo.deviceId;
const isInTWA = document.referrer.includes('android-app://com.imgress.twa');

function usePushManager ( notificationActivationState ) {
    const SavePushSubMutation = useMutation(useApi(savePushSub));
    const dispatch = useDispatch();
    const isSubSavedToDb = notificationActivationState === pushNotifStates.savedToDb
    const isAllowedByUser = (
        isInTWA ||
        (notificationActivationState === pushNotifStates.reSave) ||
        (notificationActivationState === pushNotifStates.allowedByUser)
    )
    
    useEffect(() => {
        registerServiceWorker();
    }, []);

    useEffect(() => {
        if (isAllowedByUser) {
            askPermission().then((perm) => {
                if (!isSubSavedToDb) {
                    subscribeUserToPush().then(pushSubData => {
                        SavePushSubMutation.mutateAsync(pushSubData, deviceID).then(serverRes => {
                            dispatch(setShowNotification(pushNotifStates.savedToDb))
                        })
                    })
                }
            })
        }
    }, [notificationActivationState]);
};


function askPermission() {
    return new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    })
        .then(function (permissionResult) {
            if (permissionResult !== 'granted') {
                throw new Error('We weren\'t granted permission.');
            }
        });
}


function registerServiceWorker( forceReRegister = false ) {
    let knowExeption, _serviceWorkerRegistration = false;
    return navigator.serviceWorker.getRegistration().then(serviceWorkerRegistration => {
        if(forceReRegister) return;

        if(serviceWorkerRegistration) {
            knowExeption = true;
            _serviceWorkerRegistration = serviceWorkerRegistration;
            throw new Error('Already registered');
        };
        // return serviceWorkerRegistration.unregister()
    }).then(() => {
        return navigator.serviceWorker.register('/service_worker_v1.js', {scope: '.'})
        .then(function (registration) {
            console.log('Service worker successfully registered.');
            if (!getLsItem('first-time-sw-regiter')) {
                persistThis('first-time-sw-regiter', true); 
            } else {
                store.dispatch(setShowNotification(pushNotifStates.reSave))
            }
            return registration;
        })
        .catch(function (err) {
            console.error('Unable to register service worker.', err);
        });
    }).catch( err => {
        if (!knowExeption) {
            throw err;
        }
        console.log('service worker state unchanged!')
        return _serviceWorkerRegistration;
    })
}


function subscribeUserToPush() {
    return registerServiceWorker()
        .then(function (registration) {
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                    publicVAPIDkey
                )
            };
            return registration.pushManager.subscribe(subscribeOptions);
        })
        .then(function (pushSubscription) {
            console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
            return pushSubscription;
        });
}


export default usePushManager;
