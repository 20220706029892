import { forwardRef, Fragment } from 'react';
import { memo, useEffect, useRef } from 'react';
import Check from '../../assets/icons/check-24px.svg';
import Wrong from '../../assets/icons/warning_amber-24px.svg';
import { cls } from '../../utils'
import Icon from '../icon';
import style from './style.module.css'



const Input = forwardRef(({ 
  showValidations, 
  autoFocus, 
  isloading, 
  isValid, 
  onChange, 
  value, 
  error, 
  label, 
  name, 
  ...rest
}, ref) => {
  const { current:id } = useRef(Math.random());
  const _ref = useRef();
  const internalRef = ref || _ref;
  useEffect(() => {
    if(autoFocus) {
      internalRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <div className={style.wrapper}>
      {showValidations && 
        <Fragment>
          {isloading ?
            <span className={cls(style.checkmark, style.loading)}>2</span>
            :<span className={style.checkmark}>
              {isValid?
                <Icon src={Check} alt='' />
                :<Icon src={Wrong} alt='' />
              }
            </span>
          }
        </Fragment>
      }
      <input className={cls(style.input, error && style.inputerror)} 
      value={value} onChange={onChange} id={id} ref={internalRef} name={name} 
      {...rest} />
      <label 
        htmlFor={id} 
        className={cls(
          style.label,
          error && style.error, 
          isValid && style.valid,
          value && style.float
        )}
      >{label ?? name}</label>
    </div>
  )
});

export default memo(Input);