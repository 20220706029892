import { useEffect, useState } from "react";
import { getLsItem, persistThis, sendGaEvent } from "../../utils";
import style from "./style.module.css";


function VoteTutorial({ showTime = 3500 }) {
  // const votingTutorialShown = getLsItem('votingTutorialShown') 
  const [show, setShow] = useState(!getLsItem('votingTutorialShown'));
  function close() {
    setShow(false);
    persistThis('votingTutorialShown', true)
  }

  useEffect(() => {
    if(show) {
      sendGaEvent('General', `Vote toturial shown`);
      const id = setTimeout(() => {
        close()
      }, showTime);
      return () => {
        clearTimeout(id)
      }
    }
  }, [])
  return show && <div className={style.totContainer} onClick={close}>
    <b>Tap</b><small> on a image </small><b>to vote</b></div>
}
export default VoteTutorial