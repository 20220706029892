/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { createBallot as apiCreateBallot, useApi } from '../../api';
import Add from '../../assets/icons/add_a_photo-24px.svg';
import Share from '../../assets/icons/share-24px.svg';
// import Lock from '../../assets/icons/lock-24px.svg';
import Button from '../../components/button';
import Input from '../../components/input';
// import StopWatch from '../../assets/icons/timer-24px.svg';
import ImagePreview from '../../components/imagePreview';
import Page from '../../components/page';
import RadioGroup from '../../components/radioGroup';
import Spinner from '../../components/spinner';
import { showToast } from '../../redux/appMeta';
import { addImage, nonSerializedStore, reset, setDeadLine, setIsPublic, startUpload, setDescription, setTags } from '../../redux/create';
import { ballotLinkGenerator, compressAndBase64, copytoClipboard, deMilitrizedObject, sendGaEvent, cls } from '../../utils';
import style from './style.module.css';
import Icon from '../../components/icon';
import { toastTypes } from '../../utils/constants';
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import "@pathofdev/react-tag-input/build/index.css";
import './style.css';
// import ReactTagInput from "@pathofdev/react-tag-input";
// let cocoSsd;

const preTags = [
  'selfie', 'design', 'face', 'scene', 'tattoo', 'dress', 'style', 'pet', 
'person',
'bicycle',
'car',
'motorcycle',
'airplane',
'bus',
'train',
'truck',
'boat',
'bench',
'bird',
'cat',
'dog',
'horse',
'sheep',
'cow',
'elephant',
'bear',
'zebra',
'giraffe',
'backpack',
'umbrella',
'handbag',
'tie',
'suitcase',
'frisbee',
'skis',
'snowboard',
'kite',
'skateboard',
'surfboard',
'bottle',
'cup',
'fork',
'knife',
'spoon',
'bowl',
'banana',
'apple',
'sandwich',
'orange',
'broccoli',
'carrot',
'pizza',
'donut',
'cake',
'chair',
'couch',
'bed',
'toilet',
'tv',
'laptop',
'mouse',
'remote',
'keyboard',
'microwave',
'oven',
'toaster',
'sink',
'refrigerator',
'book',
'clock',
];

function Create ({ 
  images, addImage, deadLine, setDeadLine, isPublic, setIsPublic, autoTaggingEnabled, showToast,
  reset, startUpload, allUploaded, isUploading, description, setDescription, tags, setTags, user
}) {
  const inputRef = useRef();
  const apiWrapper = useApi(apiCreateBallot);
  const isStep2 = (isUploading || allUploaded);
  const [createLoading, setCreateLoading] = useState(false);
  const [done, setDone] = useState(null);
  const queryClient = useQueryClient();
  
  async function fileInputHandler (event) { 
    for (const file of event.target.files) {
      const id = `${file.name}${file.size}`;
      nonSerializedStore[id] = deMilitrizedObject()
      nonSerializedStore[id].file = await compressAndBase64(file, { 
        fileOutput: true, 
        // worker: autoTaggingEnabled? workerRef.current : undefined
      });
      addImage({
        id,
        status: "INIT",
      })
    }
  }

  useEffect(() => {
    // console.log({isdj: inputRef?.current?.inputRef?.current})
    inputRef?.current?.inputRef?.current.setAttribute('list', 'pre-tag-list')
    return () => {
      if(done) {
        reset();
        queryClient.invalidateQueries([user.id, isPublic? 'ballots':'privateBallots']);
        queryClient.invalidateQueries(['ballots', 'user', user.id, done.id])
        queryClient.invalidateQueries(['ballots', 'all', '', ''])
      }
    }
  }, [done]);

  useEffect(() => {
    if(allUploaded) {
      sendGaEvent('Create Ballot','All ballot\'s Images uploaded', '', images.length);
      createBallotFinal()
    }
  }, [allUploaded]);

  function handleUplaod () {
    if( images.length < 2 ){
      showToast("Add 2-4 images to create a ballot", toastTypes.warn);
      sendGaEvent('Create Ballot','Less than 2 images waring shown');
      return;
    }
    if( images.length > 4 ){
      showToast("A ballot can't contain more than 4 images", toastTypes.warn);
      sendGaEvent('Create Ballot','More than 4 images warning shown');
      return;
    }
    startUpload()
  }

  function createBallotFinal () {
    setCreateLoading(true);
    const imgs = images.map(img=>({
      id: img.assetId,
      // thumbnail: img.thumbnail,
    }))
    if(description) {
      sendGaEvent('Create Ballot','Description used in Created ballot');
    }
    apiWrapper(imgs, deadLine, isPublic, description, tags).then( res => {
      sendGaEvent('Create Ballot','Created ballot api called', `Created ballot id: ${res.id}`);
      setCreateLoading(false)
      setDone(res);
    }).catch(err=>{
      sendGaEvent('Create Ballot','Created ballot api failed');
      setCreateLoading(false)
    })
  }
  return (
    <Page>
      {!allUploaded && <h1 className="h2 p2"> Create Poll </h1>}
      <main className={cls(style.main, 'px2')} >
        <div className={style.thumbnailsContainer}>
          {(images).map( (img, i) => <ImagePreview
            id={img.id}
            key={img.id}
            showDeleteButton={!allUploaded}
          />)}
          { !isUploading && !allUploaded && !createLoading && !done && images.length < 4 &&
            <label htmlFor='uploadFileInput' className={style.primaryButtonOutline}>
              <input onChange={fileInputHandler} accept="image/*" multiple max={4} type='file' id="uploadFileInput" className={style.uploadFileInput} />
              <Icon src={Add} className={style.primarySvg} alt='' />
              Add Image{images.length === 3? "": "s"}
            </label>
          }
        </div>
        { !createLoading && !done &&
          <div className="py2 mt2">
            <RadioGroup 
              name={"private"}
              onChange={setIsPublic}
              startText={'Ballot Type  '}
              endText={''}
              value={isPublic}
              radios={[
                {value: 1, text: 'Public'},
                {value: 0, text: 'Private'},
              ]}
            />
            {
              !done && 
              <form className='flex justify-center items-center mt3' onSubmit={(e)=>{
                e.preventDefault();
                createBallotFinal()
              }}>
                <Input name='description (optional)' value={description} onChange={(e)=>setDescription(e.target.value)} style={{borderColor: '#444'}}/>
              </form>
            } 
            {/* <div className="mt3">
              <ReactTagInput 
                tags={tags} 
                onChange={setTags}
                ref={inputRef}
                placeholder='tags (optional)'
                removeOnBackspace
              />
              <datalist id='pre-tag-list'>
                {preTags.map((tag, index) => <option key={index} value={tag}>{tag}</option>)}
              </datalist>
            </div>    */}
          </div>
        }
        { !done && 
          <div className={style.createContainer}>
            <Button 
              onClick={allUploaded? createBallotFinal: handleUplaod} 
              color={allUploaded? "primary": "pr-outline"}
              disabled={createLoading }
            >
              { (createLoading || isUploading)? <Spinner /> 
                :allUploaded?
                  'Create the Ballot'
                  :'Upload'
              }
            </Button>
          </div>
        }
        { createLoading &&
          <div className='center'>
            <Spinner size='100' />
          </div>
        }
        <div className={style.createdContainer} style={{display: done? 'flex': 'none'}}>
          <h2 className='h2 py1 mt1'>Ballot created 🎉</h2>
          <p className='h4 py1'><span className='h4'>{done?.coin}</span> coins added to your account.</p>
          <p className='center pt1'>Share with people you know to have their opinion:</p>
          {/* <h6>{`https://imgress.com/b?id=${done?.id}`}</h6> */}
          <Button color='primary' className='my2 flex items-center' onClick={function(event){
            copytoClipboard(ballotLinkGenerator(done.id))
            event.target.textContent = 'Link Copied!';
            sendGaEvent('Create Ballot','Created ballot link copied', `Copied ballot id: ${done.id}`);
          }}>
            <Icon light src={Share} /> Copy Link 
          </Button>
          <br />
          <Link to={`/b/${done?.id}`}><Button>Preview</Button></Link>
          <br />
        </div>
      </main>
    </Page>
  )
}


function mapStatetoProps (state, ownProps) {
  return {
    images: state.upload.images,
    isPublic: state.upload.isPublic,
    user: state.appMeta.user,
    deadLine: state.upload.deadLine,
    description: state.upload.description,
    tags: state.upload.tags,
    autoTaggingEnabled: state.appMeta.perferences.autoTagging,
    // unfinishedUpload: state.upload.images.some(img => img.status !== "INIT"),
    isUploading: state.upload.images.some(img => img.status === "UPLOADING"),
    allUploaded: (
      (state.upload.images.length > 0) && 
      state.upload.images.every(img => img.status === "SUCCESS")
    )
  }
}
function mapDispatchtoProps (dispatch, ownProps) {
  return {
    addImage: (imgObject) => dispatch(addImage(imgObject)),
    setDeadLine: (deadline) => dispatch(setDeadLine(deadline)),
    setIsPublic: (isPublic) => dispatch(setIsPublic(isPublic)),
    startUpload: () => dispatch(startUpload()),
    setDescription: (txt) => dispatch(setDescription(txt)),
    setTags: (newTags) => dispatch(setTags(newTags)),
    showToast: (text, type)=> dispatch(showToast({text, type})),
    reset: () => dispatch(reset())
  }
}
export default connect(mapStatetoProps, mapDispatchtoProps)(Create);