import { store } from "./redux/store";
import { jsonToFormdata, queryStringBuilder } from "./utils";
import { uploadApiAddress, isDevEnv } from "./utils/constants";
import request from "./utils/request";
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from "react";

export function useApi ( functionRef ) {
    const { getAccessTokenSilently  } = useAuth0();
    return useCallback(
        (...args) => {
            return getAccessTokenSilently().then( token => {
                return request(token, ...functionRef(...args))
            })
        },
        [functionRef, getAccessTokenSilently],
    );
}

export const requestArgs = ( ...args ) => args;

export function apiAddress ( ) {
    return isDevEnv? //'https://imgress-backend.mra.workers.dev' || 
    'http://localhost:3000' : store.getState().appMeta.perferences.server;
}

export function votchUser( userId) { 
    return requestArgs( `${apiAddress()}/votchrequest?id=${userId}`)
}
export function complexVotchRequest( id, isVotched, hasPendingVotchRequest) { 
    // eslint-disable-next-line no-restricted-globals
    if(!isVotched || confirm('Are you sure to unfollow?')) {
        return requestArgs( `${apiAddress()}/${
            isVotched? 
                'unvotch' 
                :hasPendingVotchRequest?
                    'undovotchrequest'
                    :'votchrequest'
            }?id=${id}`
        )
    }
    return Promise.reject()
}
export function createUser( body) { 
    return requestArgs( `${apiAddress()}/createuser`, {
        method: 'post',
        body: jsonToFormdata(body)
    })
}
export function editUser( body) { 
    return requestArgs( `${apiAddress()}/edituser`, {
        method: 'post',
        body: jsonToFormdata(body)
    })
}
export function searchUser( searchTerm) { 
    return requestArgs( `${apiAddress()}/search?${queryStringBuilder({searchTerm})}`)
}
export function usernameIsValid( username) { 
    return requestArgs( `${apiAddress()}/usernamecheck?username=${username}`)
}
export function userPrivateBallots( id) { 
    return requestArgs( `${apiAddress()}/userprivateballots?${queryStringBuilder({id})}`)
}
export function userBallots( id) { 
    return requestArgs( `${apiAddress()}/userballots?${queryStringBuilder({id})}`)
}
export function userInfoByEmail( ) { 
    return requestArgs( `${apiAddress()}/users`)
}
export function userInfo( id) { 
    return requestArgs( `${apiAddress()}/userlist?id=${id}`)
}
export function ballotsList( [beforeOrAfter, pageParam], type, matchTerm) { 
    return requestArgs( `${apiAddress()}/ballots?type=${type}&${beforeOrAfter}=${pageParam}&matchTerm=${matchTerm}`)
}
export function ballotsListPreFeed(  type, userId) { 
    return requestArgs( `${apiAddress()}/ballotsprefeed?type=${type}&userId=${userId}`)
}
export function votchRequestList( ) {
    return requestArgs( `${apiAddress()}/votchrequestlist`)
}
export function notifsSeen( ) {
    return requestArgs( `${apiAddress()}/notifseenack`)
}
export function notifCount( ) {
    return requestArgs( `${apiAddress()}/notifcount`)
}
export function notifList( ) {
    return requestArgs( `${apiAddress()}/notiflist`)
}
export function votchConfirm( id) {
    return requestArgs( `${apiAddress()}/votchconfirm?id=${id}`)
}
export function votchIgnore( id) {
    return requestArgs( `${apiAddress()}/votchignore?id=${id}`)
}
export function anonymousVoteOnBallot( firstTime, ballotId, imgId) {
    const fd = new FormData();
    fd.append('ballot_id', ballotId)
    fd.append('image_id', imgId)
    const voteEndpoint = firstTime? 
        `${apiAddress()}/anonymousballotsupdatevote`
        :`${apiAddress()}/anonymousballotsvote`;
    return requestArgs( voteEndpoint,{
        method: "post",
        body: fd
    })
}
export function voteOnBallot( firstTime, ballotId, imgId) {
    const fd = new FormData();
    fd.append('ballot_id', ballotId)
    fd.append('image_id', imgId)
    const voteEndpoint = firstTime? 
        `${apiAddress()}/updatevote`
        :`${apiAddress()}/castvote`;
    return requestArgs( voteEndpoint,{
        method: "post",
        body: fd
    })
}
export function ballotDelete( id) {
    return requestArgs( `${apiAddress()}/deleteballot?ballotId=${id}`)
}
export function anonymousBallotDetails( id) {
    return requestArgs( `${apiAddress()}/anonymousballot?id=${id}`, {tokenIsRequired: false})
}
export function ballotDetails( id) {
    return requestArgs( `${apiAddress()}/ballot?id=${id}`, {tokenIsRequired: false})
}
export function ballotVoteDetails( id) {
    return requestArgs( `${apiAddress()}/ballotvotsedetail?ballotId=${id}`)
}
export function ballotVote( id) {
    return requestArgs( `${apiAddress()}/ballotvotes?ballotId=${id}`)
}
export function friendsFeed( ) {
    return requestArgs( `${apiAddress()}/votcheesfeed`)
}
export function userList( id, isVotcher) {
    const url = isVotcher ? 
        `${apiAddress()}/votcherslist?id=${id}`
        :`${apiAddress()}/votcheeslist?id=${id}`
    return requestArgs( url)
}
export function uploadToCdn( file) { 
    const fd = new FormData();
    fd.append('image', file)
    return requestArgs( `${uploadApiAddress}/upload`, {
        method: "post",
        body: fd
    })
}
export function createBallot( images, deadLine, isPublic, description, tags) { 
    return requestArgs( `${apiAddress()}/createballot`, {
        method: "post",
        body: jsonToFormdata({
            images,
            duration: deadLine-0,
            description,
            tags,
            is_public: (isPublic - 0)
        })
    })
}
export function imgSearch( searchTerm) {
    return requestArgs( `${apiAddress()}/imgfeed?${queryStringBuilder({searchTerm})}`)
}
export function createAnonymous ( images, searchTerm) {
    return requestArgs( `${apiAddress()}/createanonymous`,{
        method: 'post',
        body: jsonToFormdata({
            images,
            tags: [searchTerm]
        })
    })
}
export function savePushSub ( pushSubObject, deviceId ) {
    return requestArgs( `${apiAddress()}/savepush`,{
        method: 'post',
        body: jsonToFormdata({
            pushSubString: JSON.stringify(pushSubObject),
            deviceId
        })
    })
}
export function allServerTags ( ) {
    return requestArgs( `${apiAddress()}/tags`)
}
export function aaaa ( ) {
    return requestArgs( `${apiAddress()}/votchrequestlist`)
}