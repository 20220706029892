// import axios from 'axios';
// import { redirectToLogin } from '.';
// import { closeLoginModal, showToast } from "../redux/appMeta";
// import { store } from "../redux/store";


export let globalLoginResolve;
// let killSwitchCounter = 0;

// function showError (err) {
//     dispatch(showToast(JSON.stringify(err)));
// }

// async function reloginProcedure () {
//     killSwitchCounter++;
//     return new Promise((res, rej)=>{
//         globalLoginResolve = res;
//         console.log('inside request')
//         redirectToLogin()
//         // dispatch(openLoginModal());
//         const rf = setTimeout(() => {
//             rej();
//         }, 20_000);
//     })
// }

export default async function request (token, url='', options = {}) {
    const {method = "get", body, headers} = options;
    let seperator = url.includes('?')? '&' : '?';
    return fetch(`${url+seperator}token=${token}`.replace('undefined',''), {
        method,
        body: body? body: undefined,
        headers: {
            ...headers,
            // Authorization: `bearer ${token()}`
        },
    }).then(res => {
        // killSwitchCounter = 0;
        if(res.ok) {
            return res.json()
        }
        //? else
        throw res
    });
}