import { cls } from '../../utils';
import Spinner from '../spinner';
import style from './style.module.css';

function Button({ loading, color='secondary', className='', children, ...rest}) {
  return (
    <button {...rest} className={cls(style.btn, style[color], className)} >
      {loading ? 
        <Spinner className={style.overlayBtn} thickness='2' size='15' />
        :children
      }
    </button>
  )
}
export default Button