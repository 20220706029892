import { createSlice } from "@reduxjs/toolkit";
import { sendGaEvent } from "../utils";

export let nonSerializedStore = Object.create(null);

const initaialState = {
    deadLine: '24',
    isPublic: 1,
    uploadStarted: false,
    description: '',
    tags: [],
    images:[],
};

export const uploadSlice = createSlice({
    name: 'upload',
    initialState: initaialState,
    reducers: {
        reset() {
            nonSerializedStore = Object.create(null);
            return initaialState;
        },
        startUpload(state, action) {
            sendGaEvent('Create Ballot','upload started by user');
            state.uploadStarted = true
        },
        setStatus(state, action) {
            const {id, status, assetId} = action.payload;
            const img = state.images.find(img => img.id === id)
            img.status = status;
            img.assetId = assetId;
        },
        setIsPublic(state, action) {
            state.isPublic = action.payload;
        },
        setDescription(state, action) {
            state.description = action.payload;
        },
        setTags(state, action) {
            state.tags = action.payload;
        },
        setDeadLine(state, action) {
            state.deadLine = action.payload;
        },
        addImage(state, action) {
            sendGaEvent('Create Ballot','Image added to ballot by user', action.payload.id);
            state.images.push(action.payload)
        },
        removeImage(state, action) {
            const id = action.payload;
            sendGaEvent('Upload Image','Images deleted by user', id);
            state.images = state.images.filter( img => img.id !== id);
        },
    }
})

export const { 
    addImage, 
    removeImage, 
    setDeadLine, 
    setIsPublic, 
    setStatus, 
    reset, 
    setTags,
    setDescription,
    startUpload 
} = uploadSlice.actions
