export const isDevEnv = process.env.REACT_APP_ENVIRONMENT === 'development';
export const release_verison = process.env.REACT_APP_VERSION;
export const commit_hash = process.env.REACT_APP_COMMIT_HASH;
console.log(release_verison);
export const auth0_client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN;
export const auth0_audience = process.env.REACT_APP_AUTH0_AUDIENCE;

export const servers = {
    local: process.env.REACT_APP_CORE_SERVER_URL,
    global: process.env.REACT_APP_EDGE_SERVER_URL,
}

export const toastTypes = {
    warn: "warn",
    success: "success",
    info: "info",
    error: "error",
}

export const themes = {
    light: "light",
    dark: "dark",
}

export const themeColors = {
    [themes.light]: {
        blue: "#005deb",
        red: "red",
        green: "green",
        dark: "rgb(12, 0, 44)",
        white: "#fff",
        feedbg: "#ccc",
        shimmerdark: "#ccc",
        shimmerwhite: "#efefef",
        boxshadowColor: "#efefef",
        secondaryColor: "#ebe8ff",
        svgColor: " invert(.33) sepia(1) saturate(84) hue-rotate(-148deg)",
        svgDefault: " invert(0) sepia(0) saturate(84) hue-rotate(-148deg)",
    },
    [themes.dark]: {
        blue: "#2983ff",
        red: "red",
        green: "green",
        dark: "#fff",
        white: "#141414",
        feedbg: "#282828",
        shimmerdark: "#141414",
        shimmerwhite: "#414141",
        boxshadowColor: "#282828",
        secondaryColor: "#29292a",
        svgColor: "invert(0.33) sepia(1) saturate(18) hue-rotate(-157deg)",
        svgDefault: " invert(0.75)",
    }
};

export const pushNotifStates = {
    reSave: "re-save",
    allowedByUser: 'allowed',
    savedToDb: 'saveddb',
}

export const uploadTypes = {
    base64: "base64",
    file: "binary",
    link: "link"
}
export const imagePreviewStats = {
    success: "SUCCESS",
    uploading: "UPLOADING",
    error: "ERROR",
    init: "INIT"
}

export const uploadApiAddress = process.env.REACT_APP_UPLOAD_SERVER_URL;
