/* eslint-disable react-hooks/rules-of-hooks */
// ;
import { Fragment, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
// import { useSelector } from 'react-redux';
import SearchIcon from '../../assets/icons/search-24px.svg'
import { searchUser, allServerTags, useApi } from '../../api';
import Page from '../../components/page';
import UserRow from '../../components/userRow';
import { UserRowSkeleton } from '../../components/Skeleton';
import Spinner from '../../components/spinner';
import style from './style.module.css';
import { cls, getUrlParam, sendGaEvent, setUrlParam, simpleDebouncer } from '../../utils';
import Icon from '../../components/icon';
import Tab from '../../components/Tab';
import { connect } from 'react-redux';
import { unSelectImage, selectImage, saveSearchterm, resetImage } from '../../redux/search';
import Button from '../../components/button';
import { showToast } from '../../components/app';
import { toastTypes } from '../../utils/constants';
import { Link } from 'react-router-dom';
// import Input from '../../components/input';

const USER_TAB = 'Users';
const TAG_TAB = 'Hashtags';

export const TagChip = ({tag}) => <Link to={`/ballots?type=tag&tagName=${tag}`} className={cls('p1 m1', style.chip)}>#{tag}</Link>

function Search ({ 
  saveSearchterm, term= getUrlParam('terms')
}) {
  
  const [tab, setTab] = useState(getUrlParam('tab')||USER_TAB);
  const [_searchTerm, _setSearchTerm] = useState(term);
  const [searchTerm, setSearchTerm] = useState(term);

  const searchQuery = useQuery(
    ['searchQuery', _searchTerm], 
    useApi(()=> searchUser(_searchTerm)), 
    {
      enabled: !!_searchTerm && (tab===USER_TAB)
    }
  );

  const debouncedReftech = useMemo(() => simpleDebouncer((inputValue) => {
    setUrlParam('terms', inputValue);
    saveSearchterm(inputValue);
    _setSearchTerm(inputValue);
    sendGaEvent('Search', `Searched for ${tab}`, `Search term: ${inputValue}`);
  }), []);

  function inputChangeHandler ({target:{value}}) {
    setSearchTerm(value);
    debouncedReftech(value);
  };
  const tagsQuery = useQuery(
    ['tagQuery'], 
    useApi(()=> allServerTags()), 
    {
      enabled: (tab===TAG_TAB)
    }
  );

  return (
    <Page>
      <header>
        <h2>
          {!searchQuery.isLoading && searchQuery.isFetching && <Spinner />}
        </h2>
        <Icon src={SearchIcon} ligh2t className={style.kk} />
        <input 
          value={searchTerm}
          className={style.ii}
          placeholder='Search '
          onChange={inputChangeHandler}
          label='search' 
        />
      </header>
      {searchQuery.isSuccess && (searchQuery.data?.length < 1?
        _searchTerm && <div className='mt2'>No user found with <code>{searchTerm}</code></div>
        :_searchTerm && <div className='mt2'>Results for search term <code>{searchTerm}</code></div>
      )}
      <Tab 
        headers={[USER_TAB, TAG_TAB]} 
        selected={tab}
        onChange={setTab}
      >
        <div user>
          {searchQuery.isLoading?
            <div className='pt3'>
              <UserRowSkeleton />
              <UserRowSkeleton />
              <UserRowSkeleton />
              <UserRowSkeleton />
            </div>
            :
            <main className='mt1'>
              {searchQuery.data?.map?.(user=><UserRow userObj={user} type='follow' />)}
              {!searchQuery.data && <p className='px2 mt4 h3 center'>Type something to search <br/> <small className='h6'>(username, description, ...)</small></p>}
            </main>
          }
        </div>
        <div tags className='flex flex-wrap'>
          {tagsQuery.isFetching && <Spinner />}
          {Array.isArray(tagsQuery.data) && tagsQuery.data.map(tag => (
            <TagChip tag={tag} />
          ))}
        </div>
      </Tab>
    </Page>
  )
}

function Image2({src, className, selected, onSelect, canBeSelected}) {
  return <img src={src} className={cls(className, selected && style.selected)} onClick={()=>{
    if(!selected && !canBeSelected) {
      showToast('A ballot can have maximum 4 images', toastTypes.info)
      return;
    }
    onSelect(!selected);
  }} alt='' />
}

function s2p(state) {
  return {
    selectedImages: state.search.selectedImages,
    term: state.search.term,
    loggedUser: state.appMeta.user,
  }
}

function d2p(dispatch, ownProps) {
  return {
    selectImage: (imgObj) => dispatch(selectImage(imgObj)),
    unSelectImage: (imgObj) => dispatch(unSelectImage(imgObj)),
    resetImage: () => dispatch(resetImage()),
    saveSearchterm: (term) => dispatch(saveSearchterm(term)),
  }
}

export default connect(s2p, d2p)(Search);
