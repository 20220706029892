import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { 
  useApi, 
  createUser as _createUser, 
  editUser as _editUser, 
  uploadToCdn, 
  usernameIsValid as _usernameIsValid 
} from '../../api';
import Add from '../../assets/icons/add_a_photo-24px.svg';
import { showToast } from '../../components/app';
import Button from '../../components/button';
import Header from '../../components/header';
import Icon from '../../components/icon';
import Input from '../../components/input';
import Page from '../../components/page';
import { logoutUser, setUser } from '../../redux/appMeta';
import { cdnLinkGenerator, deepCopy, getLsItem, randomNumber, route, sendGaEvent, simpleDebouncer } from '../../utils';
import { toastTypes } from '../../utils/constants';
import style from './style.module.css';

function UserDetails ({user={}, setUser, logoutUser}) {
  let isFirstTime = !user?.id;
  const history = useHistory();
  const queryClient = useQueryClient();
  const editUser = useApi(_editUser);
  const createUser = useApi(_createUser);
  const usernameIsValid = useApi(_usernameIsValid);
  const [isValidUsername, setIsValidUsername] = useState('tobechanged');
  const [loading, setLoading] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [imgSrc, setImgSrc] = useState();
  const usernameInput = useRef();
  const initialUsername = useRef();

  const uploadMutation = useMutation(useApi(uploadToCdn), {
    onMutate(file) {
      setImgSrc(URL.createObjectURL(file));
      sendGaEvent('Upload Image','Profile Image is uploading');
    },
    onError(err) {
      sendGaEvent('Upload Image','Profile Image upload failed');
      showToast(`Upload image failed${  err}`, toastTypes.error)
      setImgSrc(userInfo.picture)
    },
    onSuccess(data) {
      if(data.id){
        // setUserInfo({...userInfo, picture: data.id})
        userInfo.picture = data.id
        showToast(`Upload image successful`, toastTypes.success)
      }
      sendGaEvent('Upload Image','Profile Image upload Success');
    },
    onSettled(data, err, file) {
      URL.revokeObjectURL(file)
    }
  });

  function inputChangeHandler(event) {
    setLoading(true)
    if(event.target.value === user.username){
      setIsValidUsername('ok');
      setLoading(false)
      return;
    }
    usernameIsValid(event.target.value).then(res=>{
      setIsValidUsername(res.isAvalaible? "ok": false)
      setLoading(false)
    }).catch(err=>{
      setIsValidUsername(false)
      setLoading(false)
    })
  }
  
  function handleSubmit( event ) {
    event.preventDefault()
    if (isValidUsername !== 'ok') {
      showToast('Username is not available', toastTypes.error);
      sendGaEvent('User Details Page', `Invalid Username submitted`, `Submitted invalid username is: ${userInfo.username}`)
      return;
    }
    let body = {};
    const fd = new FormData(event.target);
    for (const [key, value] of fd) {
      body[key] = value;
    }
    if ( body.username === initialUsername.current ) {
      delete body.username
    }
    // if ( !body.description ) {
    //   delete body.description
    // }
    body.picture = userInfo.picture;
    if(isFirstTime) {
      event.target.innerHTML = '...Loading...'
      createUser(body).then(res=>{
        sendGaEvent('User Details Page', `New user created`, `Newly created user email: ${res.email}`)
        const _next = sessionStorage.getItem('login-state') || '/profile';
        sessionStorage.removeItem('login-state');
        route(_next)
        setTimeout(() => {
          setUser({...userInfo, ...res})
        }, 50);
      }).catch(err=>{
        console.error(err);
        sendGaEvent('User Details Page', `Create new user Failed`, `Failed to create of this email: ${userInfo.email}`);
        showToast('Account creation was unseccessful', toastTypes.error);
      })
    } else {
      editUser(body).then(res=>{
        sendGaEvent('User Details Page', `User Details edited`, `Edited user email: ${userInfo.email}`);
        //? const targetUserKey = [ loggedUser?.id, id, username, window.location.pathname];
        queryClient.invalidateQueries([res.id, '', '', '/profile']);
        showToast('Profile edited seccessfully', toastTypes.success);
        setUser(res)
        history.goBack();
        // route('/')
      }).catch(err => {
        console.error(err);
        sendGaEvent('User Details Page', `User Details edit failed`, `Failed to edit user email: ${userInfo.email}`)
        showToast('Profile edit submission was unseccessful', toastTypes.error);
      })
      //edit user
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useMemo(() => simpleDebouncer(inputChangeHandler),[])

  useLayoutEffect(() => {
    if (!user?.username) {
      const gp = getLsItem('tokenObj') || getLsItem('googleProfileObj');
      userInfo.name = gp.nickname || gp.name
      userInfo.email = gp.email
      userInfo.picture = gp.picture || gp.imageUrl;
      userInfo.username = gp.email.split('@')[0] + randomNumber(6);
      setUserInfo({...userInfo})
    } else {
      setUserInfo(deepCopy(user));
      initialUsername.current = userInfo.username;
    }
    
    setImgSrc(cdnLinkGenerator(userInfo.picture));
    setIsValidUsername('ok');
    sendGaEvent('User Details Page', `${isFirstTime? 'Create': 'Edit'} user page visited`, `${isFirstTime? 'Create': 'Edit'} page visited by email: ${userInfo.email}`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function fileInputHandler (event) { 
    for (const file of event.target.files) {
      uploadMutation.mutate(file);
    }
  }

  function fuckMynaming (e) {
    const target = e.target;
    if(target.name === 'username') {
      debouncedCallback(e);
    }
    setUserInfo({...userInfo, [target.name]: target.value})
  }

  return (
    <Page>
      {!isFirstTime && <Header text="Edit profile" />}
      <form className={style.container} onSubmit={handleSubmit}>
        {isFirstTime && <h2 className='h2 center'>Pick a username for your account</h2>}
        <label htmlFor='uploadFileInput' className='relative flex justify-end items-end'>
          <img src={imgSrc} className={style.avatar} alt=''/>
          <Icon src={Add} dark className={style.addpht} />
        </label>
        <input onChange={fileInputHandler} accept="image/*" type='file' id="uploadFileInput" className='display-none' />
        
        <Input name={'email'} autoComplete='email' disabled value={userInfo.email} onChange={fuckMynaming} />
        <Input name={'name'} autoComplete='name' required value={userInfo.name} onChange={fuckMynaming}/>
        <Input 
          ref={usernameInput}
          isloading={loading}
          isValid={isValidUsername === 'ok'}
          error={!isValidUsername}
          onChange={fuckMynaming} 
          value={userInfo.username}
          required 
          name='username' 
          showValidations
          autoComplete='username'
          pattern='\S{4,}' 
        />
        {!isFirstTime && <Input name='description' value={userInfo.description} onChange={fuckMynaming}/>}
        <div className={style.btnContainer}>
          <Button type='submit' color="primary" className='' 
          loading={loading || uploadMutation.isLoading}
          disabled={loading || uploadMutation.isLoading}
          >
            {isFirstTime? 'Create your profile':'Save changes'}
          </Button>
          {isFirstTime &&
            <Button onClick={logoutUser} color='link' className='py2' type='button'>Login with another Account</Button>
          }
        </div>
      </form>
    </Page>
  )
}

function s2p (state) {
  return {
      user: state.appMeta.user,
  }
}

function d2p (dispatch) {
  return {
    logoutUser: ()=>{
      dispatch(logoutUser())
      sendGaEvent('General', `login with another account choosed`)
    },
    setUser: (user)=>dispatch(setUser(user)),
  }
}

export default connect(s2p, d2p)(UserDetails)

