
function localStoragePerRelease (anchor, key, callback ) {
    const currentClearNumber = localStorage.getItem(key)
    if (currentClearNumber < anchor) {
        callback()
        localStorage.setItem(key, anchor)
    }
}

const ForceClearNumber = 1;
localStoragePerRelease(ForceClearNumber, 'cleared-at', () => localStorage.clear());
const ReRegisterNumber = 1;
localStoragePerRelease(ReRegisterNumber, 'service-worker', () => {
    try {
        navigator.serviceWorker.getRegistration().then(serviceWorkerRegistration => {
            return serviceWorkerRegistration.unregister()
        }).catch();
    } catch (error) {
        
    }
});
const OneTimeFixClearNumber = 1
localStoragePerRelease(OneTimeFixClearNumber, 'on-time-fix-pushnotif', () => {
    localStorage.removeItem('notificationActivationState')
});
