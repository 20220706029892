import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { closeToast } from '../../redux/appMeta';
import Warn from '../../assets/icons/warning-24px.svg';
import FeedBack from '../../assets/icons/feedback_black_24dp.svg';
import ErrorIcon from '../../assets/icons/error_black_24dp.svg';
import CheckCircle from '../../assets/icons/check_circle_black_24dp.svg';
import style from './style.module.css';
import Icon from '../icon';
import { cls, rafRunner } from '../../utils';
import { toastTypes } from '../../utils/constants';

const Icons = {
  [toastTypes.error]: ErrorIcon,
  [toastTypes.warn]: Warn,
  [toastTypes.success]: CheckCircle,
  [toastTypes.info]: FeedBack,
}

function Toast({text, type = toastTypes.warn, closer, fadeAble = true, duration = 3000}) {
  const refMain = useRef();
  const ref = useRef();
  useEffect(() => {
    let lastRafRef;
    if(fadeAble) {
      const base = (duration / 1000) * 60;
      let scale = base;
      lastRafRef = rafRunner(()=> {
        scale = scale - 1;
        try {
          ref.current.style.transform = `scaleX(${(scale / base).toFixed(5)})`;
          if (scale < (base / 5) ) {
            refMain.current.style.opacity = `${(scale / (base/10)).toFixed(1)}`;
          }
        } catch (error) {
          console.log(error)
        }
      }, base).current;
      setTimeout(() => {
        closer();
      }, duration);
    }
    return () => {
      cancelAnimationFrame(lastRafRef)
    }
  }, []);

  return(
    <div ref={refMain} className={cls(style.toastbase, style[`toast-${type}`])}>
      <Icon src={Icons[type]} />
      <p className='py1'>{text}</p>
      <div ref={ref} className={cls(style.timerIndicator, style[`toast-${type}`])} />
    </div>
  )
}
function s2p(state) {
  return {
    text: state.appMeta.hud.toast.text,
    type: state.appMeta.hud.toast.type,
  }
}
function d2p(dispatch) {
  return {
    closer: ()=> dispatch(closeToast())
  }
}
export default connect(s2p, d2p)(Toast)