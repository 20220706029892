/* eslint-disable react-hooks/rules-of-hooks */
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '../../components/button';
import Header from '../../components/header';
import Page from '../../components/page';
import RadioGroup from '../../components/radioGroup';
import SettingRow from '../../components/settingRow';
import Switch from '../../components/switch';
import { logoutUser, setAppTheme, setAutoTagging, setPushNotifState, setServer as setServerRedux } from '../../redux/appMeta';
import { isNotInBrowserEnvironment, sendGaEvent } from '../../utils';
import { commit_hash, pushNotifStates, release_verison, servers, themes } from '../../utils/constants';


function Setting ({
  logoutUser,
  theme, setTheme, server, setServer, autoTagging, setAutoTagging, pushNotifState, setPushNotifState
}) {
  if (isNotInBrowserEnvironment) return;
  const { logout } = useAuth0();
  const notifsAvailable = 'Notification' in window;
  function getNotifPermission (val) {
    sendGaEvent('Settings', 'Notification requested');
    Promise.resolve(window.Notification.requestPermission()).then((result) => {
      sendGaEvent('Settings', `Notifications are ${  result}`);
      setPushNotifState(result);
    })
  }

  return (
    <Page>
      <Header text='Settings' />
      <SettingRow>
        <span>Theme</span>
        <RadioGroup
          name={"theme"}
          onChange={setTheme}
          value={theme}
          radios={[
            {value: themes.light, text: 'Light'},
            {value: themes.dark, text: 'Dark'},
          ]}
        />
      </SettingRow>
      <SettingRow disabled={!notifsAvailable}>
        {[
          <span>Notifications</span>,
          notifsAvailable?
            <Switch label='' value={pushNotifState=== pushNotifStates.granted} onChange={getNotifPermission} />
            :<span className='h5 right-align'>This feature is not supported in your browser</span>
        ]}        
      </SettingRow>
      <SettingRow disabled={true}>
        {[
          <span>Auto Tagging Images</span>,
          window.Worker?
            <Switch label='' value={autoTagging} onChange={setAutoTagging} />
            :<span className='h5 right-align'>This feature is not supported in your browser</span>
        ]}
      </SettingRow>
      <SettingRow>
        <span>Server Location</span>
        <RadioGroup
          name={"server_location"}
          onChange={setServer}
          value={server}
          radios={[
            {value: servers.global, text: 'Edge'},
            {value: servers.local, text: 'Core'},
          ]}
        />
      </SettingRow>
      <SettingRow text="">
        <Link to='/terms' className='normalText'>Terms</Link>
      </SettingRow>
      <SettingRow text="">
        <Link to='/privacy' className='normalText'>Privacy policies</Link>
      </SettingRow>
      <SettingRow >
        <span style={{opacity:.7}}>Deactivate Account  (Coming soon)</span>
        {/* <Switch label='toggle' /> */}
      </SettingRow>
      <SettingRow text="">
        <Button color='link' onClick={()=>{
          logout({
            returnTo: window.location.origin
          })
        }}>Logout from Account</Button>
        {/* <Switch label='toggle' /> */}
      </SettingRow>
      <footer className="flex px2 pt2 items-end justify-end">
        <p className='h4'>v{`${release_verison}`}</p>
        &nbsp;
        {commit_hash && <sub className='h6'>{`[ ${commit_hash} ]`}</sub>}
      </footer>
    </Page>
  );
}

function s2p(state, ownProps) {
  return {
    theme: state.appMeta.perferences.theme,
    server: state.appMeta.perferences.server,
    autoTagging: state.appMeta.perferences.autoTagging,
    pushNotifState: state.appMeta.notification.pushNotifState,
  }
}

function d2p(dispatch, ownProps) {
  return {
    setTheme: (newTheme)=> dispatch(setAppTheme(newTheme)),
    setServer: (newServer)=> dispatch(setServerRedux(newServer)),
    setAutoTagging: (val)=> dispatch(setAutoTagging(val)),
    setPushNotifState: (val)=> dispatch(setPushNotifState(val)),
    logoutUser: ()=>{
      sendGaEvent('Settings', `Logout clicked From setting page link`);
      dispatch(logoutUser())
    },
  }
}

export default connect(s2p, d2p)(Setting);
