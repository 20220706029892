import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import BottomNav from './bottomNav';
import { useEffect, useLayoutEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { setAppTheme, setTokens, setUnseenNotifCount, setUser, showToast as _showToast } from '../redux/appMeta';
import { themeColors, themes } from '../utils/constants';
import Toast from './toast'
import Splash from './splash';
import { useApi, userInfoByEmail } from '../api';
import Home from '../routes/home';
import _CreateUser from '../routes/userDetails';
import _Profile from '../routes/profile';
import _Create from '../routes/create';
import _SingleBallot from '../routes/singleBallot';
import _Setting from '../routes/setting';
import _Notification from '../routes/notification';
import _Friends from '../routes/friends';
import _Privacy from '../routes/privacy';
import _Ballots from '../routes/ballots';
import _Terms from '../routes/terms';
import Login from '../routes/login';
import _Search from '../routes/search';
import _UserList from '../routes/userlist';
import usePushManager from '../utils/push';

const CreateUser = withAuthenticationRequired(_CreateUser)
const Profile = withAuthenticationRequired(_Profile)
const Create = withAuthenticationRequired(_Create)
const SingleBallot = withAuthenticationRequired(_SingleBallot)
const Setting = withAuthenticationRequired(_Setting)
const Notification = withAuthenticationRequired(_Notification)
const Friends = withAuthenticationRequired(_Friends)
const Privacy = withAuthenticationRequired(_Privacy)
const Ballots = withAuthenticationRequired(_Ballots)
const Terms = withAuthenticationRequired(_Terms)
const Search = withAuthenticationRequired(_Search)
const UserList = withAuthenticationRequired(_UserList)

export const history = createBrowserHistory();
export let Sentry = {};
export let showToast = {};


function App ({ appTheme, toastIsOpen, showToastCallback, setUser, notificationActivationState }) {
  const {
    isLoading,
    isAuthenticated,
    error
  } = useAuth0();
  const userFetchApi = useApi(userInfoByEmail);

  usePushManager( notificationActivationState );

  useLayoutEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      setAppTheme(e.matches?  themes.dark : themes.light)
    })
  }, []);

  useLayoutEffect(() => {
    showToast = showToastCallback;
    for( const [varName, value] of Object.entries(themeColors[appTheme])) {
      document.documentElement.style.setProperty(`--${varName}`, value);
    }
  }, [appTheme, showToastCallback]);

  useEffect(() => {
    if(window.location.hostname === 'imgress.com'){
      import('../utils/sentry').then( _ => {
        Sentry = _.default;
      })
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      userFetchApi().then(userObj => {
        setUser(userObj);
      }).catch(statusCode=> {
        // creating a new user
        if (statusCode === 569) {
          history.push('/user')
        }
      })
    }
  }, [isAuthenticated, setUser, userFetchApi]);

  if (isLoading) {
    return <Splash />;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <div id="app"> 
      <Router history={history}>
        <Suspense fallback={<Splash rotating/>}>
          <Switch>
            <Route path="/create" component={Create}/>
            <Route path="/profile" component={Profile}/>
            <Route path="/create" component={SingleBallot}/>
            <Route path="/setting" component={Setting}/>
            <Route path="/notification" component={Notification}/>
            <Route path="/friends" component={Friends}/>
            <Route path="/privacy" component={Privacy}/>
            <Route path="/ballots" component={Ballots}/>
            <Route path="/terms" component={Terms}/>
            <Route path="/login" component={Login}/>
            <Route path="/search" component={Search}/>
            <Route path="/userlist" component={UserList}/>
            <Route path="/user" component={CreateUser}/>
            <Route path="/b/:id?" component={SingleBallot}/>
            <Route path="/auth" component={Login}/>
            <Route path="/u/:id" component={Profile}/>
            <Route path="/followers/:id/:name/:count?" component={() => <UserList votchers />}/>
            <Route path="/followees/:id/:name/:count?" component={() => <UserList votchees />}/>
            <Route path="/" component={Home}/>
          </Switch>
        </Suspense>
        {isAuthenticated && <BottomNav /> }
      </Router> 
      {toastIsOpen && <Toast />}
    </div>
  );
}

function s2p (state) {
  return {
    toastIsOpen: state.appMeta.hud.toast.isOpen,
    appTheme: state.appMeta.perferences.theme,
    hasToken: state.appMeta.hasToken,
    notificationActivationState: state.appMeta.notification.notificationActivationState
  }
}
function d2p (dispatch) {
  return {
    showToastCallback: (text, type)=> dispatch(_showToast({text, type})),
    setUnseenNotifCount: (r) =>dispatch(setUnseenNotifCount(r)),
    setTokens: (t) =>dispatch(setTokens(t)),
    setUser: (userObj)=>dispatch(setUser(userObj)),
  }
}

export default connect(s2p,d2p)(App)