/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import Spinner from '../../components/spinner';
import { getLsItem, persistThis, route, sendGaEvent } from '../../utils';
import style from './style.module.css';
import { globalLoginResolve } from '../../utils/request';
import { connect } from 'react-redux';
import { closeLoginModal, setUser, setTokens, logoutUser } from '../../redux/appMeta';
import UserDetails from '../../routes/userDetails';
import { useApi, userInfoByEmail as _userInfoByEmail } from '../../api';
import Page from '../../components/page';
import { useAuth0 } from "@auth0/auth0-react";
import Splash from "../../components/splash";


function Login ({ 
  next, isCountinue, user, loginUser, setTokens, logoutUser, isLoggedIn,
  text, type = "login" }) {
  const userInfoByEmail = useApi(_userInfoByEmail);
  const [loading, setLoading] = useState();
  const [waiting, setWaiting] = useState(true);
  const [error, setError] = useState();
  const [successMessage, setMessage] = useState();
  const { loginWithRedirect, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    if(!isLoading) {
      if (isAuthenticated) {
        getIdTokenClaims().then(successResponse => {
          // console.log(successResponse)
          //? ||next added for if(typeof globalLoginResolve === 'function') { ...
          const _next = sessionStorage.getItem('login-state') || next || '/profile';
          persistThis("tokenObj", successResponse);
          persistThis("id_token", successResponse.__raw);
          setTokens(successResponse)
          setLoading(true)
          if(typeof globalLoginResolve === 'function') {
            sendGaEvent('Login Page', `User token refreshed during app usage`);
            globalLoginResolve(true);
          }
          if (user?.id) {
            sendGaEvent('Login Page', `User token refreshed`);
            route(_next)
            // closeLogin()
          }else{
            userInfoByEmail().then(userObj => {
              console.log({userInfoByEmail: userObj})
              sendGaEvent('Login Page', `UserInfoByEmail successful`, `Fetched User Email is: ${userObj.email}`);
              loginUser(userObj)
              sessionStorage.removeItem('login-state');
              route(_next)
              // closeLogin()
            }).catch(responseObj=> {
              sendGaEvent('Login Page', `UserInfoByEmail api was unsuccessful`, `UserInfoByEmail status ${responseObj}`);
              // console.log('cath called', statusCode)
              // creating a new user
              console.log({statusCode: responseObj})
              if (responseObj.status === 569) {
                route('/user')
                // closeLogin()
              }
            })
          }
        })
      } else {
        const useless = 1;
        if(isCountinue) {
          setWaiting(false)
        }else {
          //? login page
          loginWithRedirect({
            appState: next,
            id_token_hint: getLsItem("id_token") || '',
            login_hint: user?.email,
            screen_hint: getLsItem("id_token")? '' :'signup'
          })
        }
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if(next) persistThis('login-state', next);
    const _id = setTimeout(() => {
      sendGaEvent('Error', 'LocalStorage Clear')
      localStorage.clear()
      window.location('/')
    }, 7 * 1000);
    return () => clearTimeout(_id);
  }, []);

  return (
    <Page>
      <div className={style.login}>
        { waiting && <Splash rotating={isCountinue} /> }
        {/* <button onClick={() => loginWithPopup()}>Log In popup</button> */}
        {text && <div className={style.textWrapper}>{text}</div>}
        {error && <div className={style.error}>{error}</div>}
        {successMessage && <div className={style.message}>{successMessage}</div>}
        {successMessage && loading &&
          <UserDetails />
        }
        {loading? 
          <div className='flex flex-column items-center justify-center'><h1 className='h2 py2'>Fetching user info</h1><Spinner size='24' /></div>
          :
          <div className={style.loginContainer}>
            <p>To view <code>{next}</code></p>
            <button className={style.submitButton} onClick={() => loginWithRedirect({
              appState: next
            })}>Continue with your account</button>
            <br />
          </div>
        }
      </div>
    </Page>
  )
}

function s2p (state, ownProps) {
  return {
    isOpen: state.appMeta.hud.loginModal.isOpen,
    text: state.appMeta.hud.loginModal.text,
    tokenObj: state.appMeta.tokens,
    isLoggedIn: state.appMeta.tokens?.expires_at,
    user: state.appMeta.user
  }
}
function d2p (dispatch, ownProps) {
  return {
    loginUser: (user)=>dispatch(setUser(user)),
    logoutUser: ()=>dispatch(logoutUser()),
    closeLogin: ()=>dispatch(closeLoginModal()),
    setTokens: (tokenObj)=>dispatch(setTokens(tokenObj)),
  }
}

export default connect(s2p, d2p)(Login)
