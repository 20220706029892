import { NavLink, useHistory } from 'react-router-dom';
// import PollingRound from '../../assets/icons/poll-24px-logo.svg'
import ArrowBack from '../../assets/icons/arrow_back-24px.svg';
import style from './style.module.css';
import Button from '../button';
import Icon from '../icon';
import { useAuth0 } from '@auth0/auth0-react';

const Header = ({ text, isHome, ctaCallbacks }) => {
  const { isAuthenticated } = useAuth0();
  const history = useHistory();
  return (
    <>
    <header className={style.header}>
      {text? 
        <div className='flex items-center'>
          <Button color="secondary" className='items-center flex left p0 pr2' onClick={()=>history.goBack()}>
            <Icon src={ArrowBack} />
          </Button>
          <h1 className='h3'>{text}</h1>
        </div>
        :<NavLink activeClassName={style.active} className={style.homePage} to="/">
          <h1>Imgress</h1>
          <h2 className='h5'>Image voting platform</h2>
        </NavLink>
      }    
      {isHome && !isAuthenticated && 
        <NavLink to='/ballots'>
          <Button color="pr-outline" className='py1 '  onClick={ctaCallbacks.login}>
            Go to web app
          </Button>
        </NavLink>
      }
    </header>
    <div className={style.headerHeight}></div>
    </>
  )
};

// function Logo() {
//   return(
//     <div className={style.logoContainer}>
//       <span className={style.logoIconWrapper}>
//         <Icon src={PollingRound} alt='' className={style.logoIcon} />
//       </span>
//       <span>Imgress</span>
//     </div>
//   )
// }

export default Header;
