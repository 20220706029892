// import style from './style.module.css';

import { Fragment, useRef } from "react";
import { randomNumber } from "../../utils";

function Switch({value, checked, label, onChange, className}) {
  const ref = useRef(randomNumber());
  return (
    <span>
      <label htmlFor={ref.current}>{label}</label>
      <input id={ref.current} type='checkbox' checked={value || checked} onChange={(e)=>{
        onChange(e.target.checked)
      }} />
    </span>
  )
}

export default Switch;
