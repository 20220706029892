  /* eslint-disable react-hooks/rules-of-hooks */
import { Link, useHistory, useParams } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { connect } from 'react-redux';
import { userInfo, userBallots, userPrivateBallots, complexVotchRequest, useApi } from '../../api';
import Lock from '../../assets/icons/lock-24px.svg';
import ShareIcon from '../../assets/icons/share-24px.svg';
import Gear from '../../assets/icons/settings-24px (1).svg';
import ArrowBack from '../../assets/icons/arrow_back-24px.svg';
import Button from '../../components/button';
import SingleBallotRow from '../../components/singleBallotRow';
import Skeleton, { SingleBallotRowSkeleton } from '../../components/Skeleton';
import Tab from '../../components/Tab';
import { logoutUser, setUser } from '../../redux/appMeta';
import { cdnLinkGenerator, cls, copytoClipboard, deepCopy, userLinkGenerator, sendGaEvent } from '../../utils';
import style from './style.module.css';
import MoreVert from '../../assets/icons/more_vert-24px.svg'
import Icon from '../../components/icon';
import BottomSheet from '../../components/bottomSheet';
import Spinner from '../../components/spinner';
import { currentVotchStateDefiner, nextVotchState, votchStateToUserData, votchStates } from '../../utils/votchStateManager';
import Page from '../../components/page';
import BottomSheetRow from '../../components/bottomSheetRow';
import { useAuth0 } from "@auth0/auth0-react";


function ternary ( first, second ) {
  return first ?? second
}

function Profile ({ loggedUser, logoutUser}) {
  const history = useHistory();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { logout } = useAuth0();
  const isSelfProfile = window.location.pathname === '/profile';
  const targetUserKey = [ loggedUser?.id, id, window.location.pathname];
  const [bottomSheet, setBottomSheet] = useState(false);
  const targetUser = useQuery(
    targetUserKey,
    useApi(()=>userInfo(isSelfProfile? loggedUser.id: id)),
    {
      placeholderData: isSelfProfile? loggedUser: undefined,
    }
  );
  const targetUserBallots = useQuery(
    [targetUserKey, 'ballots'],
    useApi(()=>userBallots(id||targetUser.data?.id))
  )
  const targetPrivateUserBallots = useQuery(
    [targetUserKey, 'privateBallots'],
    useApi(()=>userPrivateBallots(id||targetUser.data?.id))
  )
  const currentVotchState = currentVotchStateDefiner(
    targetUser.data?.is_votched, targetUser.data?.pending_votch
  );
  const votchMutation = useMutation(
    useApi(()=>complexVotchRequest(
      targetUser.data?.id,
      targetUser.data?.is_votched, 
      targetUser.data?.pending_votch
    )),
    {
      onSuccess () {
        const targetUserClone = deepCopy(targetUser.data);
        const _nextVotchState = nextVotchState(currentVotchState);
        const {pending_votch, is_votched} = votchStateToUserData(_nextVotchState);
        targetUserClone.pending_votch = pending_votch;
        targetUserClone.is_votched = is_votched;
        sendGaEvent("Profile", `Votch state changed from ${currentVotchState} to ${_nextVotchState}`, `target user: ${loggedUser.id}`);
        queryClient.setQueryData(targetUserKey, targetUserClone);
      }
    }
  );

  return (
    <Page> 
      <header className="flex flex-column">
        <div className={cls("flex justify-between items-center px1 mb3 pb2 mellowBorderBottom")}>
          <Icon src={ArrowBack} onClick={()=>history.goBack()} />
          <span className='h4'>
            @{
              targetUser.isFetching?
              <Skeleton width={80} height='10' />
              :targetUser.data?.username
            }
          </span>
          <button onClick={()=>{
            setBottomSheet(true);
            sendGaEvent("Profile", `More menu clicked`, `more menu user: ${targetUser.data?.id}`);
          }} className={style.more}>
            <Icon src={MoreVert} />
          </button>
        </div>
        <div className="flex mb3 justify-between px2">
          <div className="flex flex-column justify-around items-start">
            <h2 className="h3">
              {targetUser.isError && <div className='px2 py1 h3'>User not found</div>}
              {targetUser.isFetching?
                <Skeleton width={100} height='15' />
                :targetUser.data?.name 
              }
            </h2>
            {targetUser.isFetching?
              <Skeleton width={200} height='45' className="my1" />
              :targetUser.data?.description && <p className='my2 h4'>{targetUser.data?.description}</p>
            }
          </div>
          <Link to={isSelfProfile?'/user': null} className='flex text-decoration-none'>
            {targetUser.data?.picture ?
              <img width="64" height='64' className={style.avatar} src={cdnLinkGenerator(targetUser.data?.picture)} alt="profile image" />
              :
              <Skeleton width={100} circle />
            }
          </Link>
        </div>
        <div className="flex items-center justify-between px2">
          <span className='flex flex-column justify-center items-center'>
            <span className={style.number}>{
              ternary(
                targetUser.data?.total_votes,
                <Skeleton height='15' width='20' />
              )
            }</span>
            <span>{` votes `}</span>
          </span>
          <div className='flex flex-column justify-center'>
            <AzInaDg 
              link={`/followees/${targetUser.data?.id}/${targetUser.data?.name}/${targetUser.data?.votchees}`} 
              txt1={targetUser.data?.votchees} 
              txt2={` following `} 
            />
            <AzInaDg 
              link={`/followers/${targetUser.data?.id}/${targetUser.data?.name}/${targetUser.data?.votchers}`} 
              txt1={targetUser.data?.votchers} 
              txt2={` followers`} 
            />
          </div>
          { isSelfProfile?
            // <Link to='/user'><Button>Edit profile</Button></Link>
            <>
              <Link to='/user' className='text-decoration-none'>
                <Button color='pr-outline' className='flex items-center flex-auto'>Edit profile</Button>
              </Link>
              <Link to='/setting'>
                <Icon src={Gear} className='' />
              </Link>
            </>
            :<Button 
              onClick={()=> votchMutation.mutate()} 
              loading={targetUser.isFetching || votchMutation.isLoading}
              color={currentVotchState=== votchStates.notVotched? "primary": "pr-outline"}
            >
              {currentVotchState}
            </Button>
          } 
        </div>
      </header>
      <hr style={{opacity: .1}} className="mx2 mt2 mb1" />
      <div className="">
        <Tab headers={['Public','Private']}>
          <div>
            { targetUserBallots.isFetching &&
              <div className='center'><Spinner size='48' /></div>
            }
            {targetUserBallots.isLoading?
              <Fragment>
                <SingleBallotRowSkeleton />
                <SingleBallotRowSkeleton />
                <SingleBallotRowSkeleton />
                <SingleBallotRowSkeleton />
                <SingleBallotRowSkeleton />
              </Fragment>
              :
              targetUserBallots.data?.length > 0 ?
              targetUserBallots.data?.map( (ballot, index) =><SingleBallotRow key={index} user={targetUser.data} ballot={ballot} />)
                :<div className="center mt2">{
                  isSelfProfile?
                    <p className="flex flex-column mt2">You have no public ballot yet, <Link to='/create'><Button className='my2' color='primary'>Create</Button></Link></p>
                    :`${targetUser.data?.name} currently doesn't have an active public ballot`
                }</div>
            }
          </div>
          <div>
            {
              targetPrivateUserBallots.isLoading?
                <Fragment>
                  <SingleBallotRowSkeleton />
                  <SingleBallotRowSkeleton />
                  <SingleBallotRowSkeleton />
                </Fragment>
              :
              targetPrivateUserBallots.data?.forbidden?
              <div className="center mt1">
                <p className='mb1'>You Need Follow them to view their private posts.</p>
                <Icon src={Lock} />
              </div>
              :
              targetPrivateUserBallots.data?.length > 0 ?
              targetPrivateUserBallots.data?.map( (ballot, index) =><SingleBallotRow key={index} user={targetUser.data} ballot={ballot} />)
                :<div className="center mt2">{
                  isSelfProfile?
                    <p className="flex flex-column mt2">You have no private Polls yet, <Link to='/create'><Button className='my2' color='pr-outline'>Create</Button></Link></p>
                    :`${targetUser.data?.name} currently doesn't have an active private ballot`
                }</div>
            }
          </div>
        </Tab>
      </div>
      <BottomSheet open={bottomSheet} onClose={()=>setBottomSheet(false)}>
        <BottomSheetRow>
          <Button onClick={function(event){
            copytoClipboard(userLinkGenerator(targetUser.data))
            event.target.textContent = 'Link Copied!';
            sendGaEvent("Profile", 'User link copied', `user id of copied link ${targetUser.data?.id}`);
          }} className="flex items-center">
            <Icon src={ShareIcon} />
            {'   Copy profile Link'}
          </Button>
        </BottomSheetRow>
        {isSelfProfile && 
        <>
          {/* // <Link to='/setting' className="text-decoration-none">
          //   <Button color='link' className='flex items-center'><Icon src={Gear} /> Setting</Button>
          // </Link>, */}
          <Button color='red' onClick={()=>{
            logout({
              returnTo: window.location.origin
            })
            sendGaEvent("Profile", 'Logout from profile page clicked', targetUser.data?.id);
          }}>Logout</Button>
        </>
        }
        {/* {!isSelfProfile && [
          <Button>Mute (Feature Not available yet)</Button>,
          <Button>Block (Feature Not available yet)</Button>,
          <Button color='red'>Report (Feature Not available yet)</Button>
        ]} */}
      </BottomSheet>
    </Page>
  );
}

function AzInaDg ({link='', txt1, txt2}) {
  return (
    <Link className={style.votch} to={link}>
      <span className={style.number}>{
        ternary(
          txt1,
          <Skeleton height='15' width='20' />
        )
      }</span>
      <span>{txt2}</span>
    </Link>
  )
}


function s2p (state, ownProps) {
  return {
    loggedUser: state.appMeta.user,
  }
}

function d2p (dispatch, ownProps) {
  return {
    setUser: (userObj)=>dispatch(setUser(userObj)),
    logoutUser: ()=>dispatch(logoutUser())
  }
}
export default connect(s2p, d2p)(Profile)