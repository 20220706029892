import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { connect } from 'react-redux';
import RetryIcon from '../../assets/icons/cached-24px.svg'
import TrashIcon from '../../assets/icons/delete-24px.svg'
import Check from '../../assets/icons/check_box-24px.svg'
import { nonSerializedStore, removeImage, setStatus } from '../../redux/create';
import Spinner from '../spinner';
import style from './style.module.css'
import { imagePreviewStats } from '../../utils/constants';
import { uploadToCdn, useApi } from '../../api';
import { sendGaEvent } from '../../utils';
import Icon from '../icon';


function ImagePreview ({ file, onDelete, showDeleteButton, status, uploadStarted, setStatus }) {
  const isSuccess = status === imagePreviewStats.success;
  const isLoading = status === imagePreviewStats.uploading;
  const isError = status === imagePreviewStats.error;
  const shouldMutate = (status === imagePreviewStats.init) || isError;
  const [imgSrc, setImgSrc] = useState();
  const [deleted, setDeleted] = useState(false);
  const {mutate} = useMutation(useApi(() => uploadToCdn(file)), {
    onMutate() {
      sendGaEvent('Upload Image','Single Image is uploading');
      setStatus({
        status: "UPLOADING"
      })
    },
    onError() {
      sendGaEvent('Upload Image','Single Image upload failed');
      setStatus({
        status: "ERROR"
      })
    },
    onSuccess(data) {
      if(data.id){
        sendGaEvent('Upload Image','Single Image upload was success', data.id);
        setStatus({
          status: "SUCCESS",
          assetId: data.id
        })
      }else {
        sendGaEvent('Upload Image','Single Images is failed for unkown');
        alert("error incdn response")
      }
    },
  });

  useEffect(() => {
    if(file){
      setImgSrc(URL.createObjectURL(file))
    }
    return()=>{
      if(file) URL.revokeObjectURL(file)
    }
  }, []);

  useEffect(() => {
    if(uploadStarted && shouldMutate){
      mutate()
    }
  }, [uploadStarted, shouldMutate, mutate])

  return (
    <div className={`${style.previewContainer} ${deleted? style.fadeDie : ''}`}>
      <img src={imgSrc} alt="" className={`${style.imgPrev} ${isLoading || isError? style.semiVisible: ''}`} loading='lazy' />
      {isLoading && <span style={{position: 'absolute'}}><Spinner size={70} /></span>} 
      {isError && <Icon className={style.retry} src={RetryIcon} onClick={mutate} />} 
      {isSuccess && <span className={style.imgUploaded}>
        <img alt='' className={style.primarySvg} src={Check} />
        </span>
      }
      { showDeleteButton && !isSuccess && !isLoading &&
        <button className={style.deleteButton} onClick={()=>{
          setDeleted(true);
          setTimeout(() => {
            onDelete();
          }, 200);
        }}>
          <Icon src={TrashIcon} className={style.primarySvg} alt='' />
        </button>
      }
    </div>
  )
}

function mapStatetoProps (state, ownProps) {
  const uploadType = state.appMeta.perferences.uploadType
  const img = state.upload.images.find(image => image.id === ownProps.id);
  return {
    uploadType,
    file: nonSerializedStore[ownProps.id].file,
    status: img.status,
    uploadStarted: state.upload.uploadStarted
  }
}
function mapDispatchtoProps (dispatch, ownProps) {
  return {
    onDelete: () => {
      dispatch(removeImage(ownProps.id));
    },
    setStatus: ({status, assetId}) => dispatch(setStatus({
      assetId,
      status,
      id: ownProps.id,
    }))
  }
}
export default connect(mapStatetoProps, mapDispatchtoProps)(ImagePreview);

