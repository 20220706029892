
import ContentLoader from "react-content-loader"
import { multiply } from "../../utils"
import style from './style.module.css';

export function SingleBallotPageSkeleton ({count = 4}) {
  return (
    <div className={style['page-container']}>
      <div className={style['ps1']}>
        <UserRowSkeleton />
        <Skeleton width='70' height='20' />
      </div>
      <div className={style['ps2']}>
        {multiply(<Skeleton width='150' square m='12px 0px' />, count)}
      </div>
    </div>
  )
}

export function UserRowSkeleton () {
  return (
    <div className={style['u-container']}>
      <div className={style['u1']}>
        <Skeleton width='50' height='50' circle m='6px 3px' />
      </div>
      <div className={style['u2']}>
        <Skeleton width='100' height='15' m='3px 0' />
        <Skeleton width='120' height='15' m='3px 0' />
      </div>
    </div>
  )
}

export function SingleBallotRowSkeleton () {
  return (
    <div className={style['sbrk-container']}>
      <div className={style['sbrk-s1']}>
        <Skeleton width='40' square m='6px 3px' />
        <Skeleton width='40' square m='6px 3px' />
        <Skeleton width='40' square m='6px 3px' />
      </div>
      <div className={style['sbrk-s2']}>
        <Skeleton width='100' height='15' m='3px 0px' />
        <Skeleton width='100' height='15' m='3px 0px' />
      </div>
    </div>
  )
}



function Skeleton ({
  m='',
  speed=2,width= 100, height= 100, square=false, circle=false, ...rest
}) {
  const _height = (circle||square)? width: height;
  return (
      <ContentLoader 
        speed={speed}
        width={width}
        height={_height}
        backgroundColor="var(--shimmerwhite)"
        foregroundColor="var(--shimmerdark)"
        {...rest}
        style={{margin: m}}
      >
        {circle ? 
          <circle cx={width/2} cy={width/2} r={width/2} /> 
          :
          <rect x='0' y='0' rx="3" ry="3" width={width} height={_height} /> 
        }
      </ContentLoader>
  )
}
export default Skeleton
