import { useState } from 'react';
import { getUrlParam, setUrlParam } from '../../utils';
import style from './style.module.css';

export default function Tab({ children, headers= [], selected= getUrlParam('tab'), onChange= ()=>0 }) {
  let init;
  const [currentTab, setCurrentTab] = useState(
    ~(init = headers.findIndex((h)=>h===selected))? init: 0
  );
  return (
    <div className={style.tabContainer}>
      <div className={style.tabHeadContainer}>
        {headers.map( (str,i)=> <span 
          className={`${style.tabHead} ${currentTab=== i? style.selected:''}`}
          key={str}
          onClick={()=>{
            setCurrentTab(i);
            onChange(headers[i]);
            setUrlParam('tab', headers[i]);
          }}
          >{str}</span>
        )}
      </div>
      <div className={style.children}>
        {children[currentTab]}
      </div>
    </div>
  );
}

