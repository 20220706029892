import { Fragment, memo } from 'react';
import Icon from '../icon';
import style from './style.module.css';

function RadioGroup ({icon, startText, endText, onChange, name, value, radios}) {
  return (
    <div className={style.radioContainer}>
      <Icon src={icon} className={style.img} alt='' /> {startText}
      <div>
        {radios.map((radio, index) => <Fragment key={index}>
          <input className={style.radio} 
            name={name} 
            type="radio" 
            onChange={(e)=>onChange(radio.value)} 
            id={radio.value}
            checked={radio.value === value}
          />
          <label htmlFor={radio.value}>{radio.text || radio.value}</label>
          </Fragment>
        )}
      </div>
       {endText}
    </div>
  )
}

export default memo(RadioGroup)