// import style from './style.module.css';

function SettingRow({text, disabled, children}) {
  return (
    <div className='flex justify-between items-center p2 mellowBorderBottom' style={{opacity: disabled? .7: 1}}>
      {/* <span>{text}</span> */}
      {children}
    </div>
  )
}

export default SettingRow;
