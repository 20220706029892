import { createSlice } from "@reduxjs/toolkit";
import { getLsItem, persistThis, sendGaEvent } from "../utils";
import { pushNotifStates, servers, themes, uploadTypes } from "../utils/constants";

const osTheme = window.matchMedia('(prefers-color-scheme: dark)');

export const appMetaSlice = createSlice({
    name: 'appMeta',
    initialState: {
        user: {},
        tokens: getLsItem("tokenObj") || {},
        notification: {
            unSeenNotifCount: 0,
            pushNotifState: getLsItem("pushNotiState") || pushNotifStates.default,
            notificationActivationState: getLsItem('notificationActivationState'),
        },
        perferences: {
            uploadType: uploadTypes.file,
            server: servers.global,
            autoTagging: getLsItem('autoTagging') || false,
            theme: osTheme.matches? themes.dark : themes.light
        },
        hud: {
            toast: {
                isOpen: false,
                text: ''
            },
            loginModal: {
                isOpen: false,
                text: ''
            }
        }
    },
    reducers: {
        setUser(state, action) {
            state.user = { ...state.user, ...action.payload };
            // persistThis("localProfileObj", state.user)
        },
        setTokens(state, action) {
            state.tokens = action.payload;
            state.hasToken = true;
        },
        setLoginState(state, action) {
            state.isLoggedIn = false;
        },
        closeToast(state, action) {
            state.hud.toast.isOpen = false;
        },
        showToast(state, action) {
            state.hud.toast.isOpen = true;
            sendGaEvent('Settings', `Toast shown`, `Toast type ${action.payload.type}`);
            state.hud.toast.text = action.payload.text;
            state.hud.toast.type = action.payload.type;
        },
        openLoginModal(state, action) {
            state.hud.loginModal.isOpen = true;
            state.hud.loginModal.text = action.payload;
        },
        closeLoginModal(state, action) {
            state.hud.loginModal.isOpen = false;
        },
        setUnseenNotifCount(state, action) {
            state.notification.unSeenNotifCount = action.payload;
        },
        setPushNotifState(state, action) {
            persistThis("pushNotiState", action.payload)
            state.notification.pushNotifState = action.payload;
        },
        setAppTheme(state, action) {
            sendGaEvent('Settings', `App Theme Changed`, `App Theme value is ${action.payload}`);
            state.perferences.theme = action.payload;
        },
        setAutoTagging(state, action) {
            sendGaEvent('Settings', `Auto Tagging Changed`, `Auto Tagging value is ${action.payload}`);
            persistThis("autoTagging", action.payload)
            state.perferences.autoTagging = action.payload;
        },
        setShowNotification(state, action) {
            // sendGaEvent('Settings', `showNotification Changed`, `showNotification setting value is ${action.payload}`);
            persistThis("notificationActivationState", action.payload)
            state.notification.notificationActivationState = action.payload;
        },
        setServer(state, action) {
            sendGaEvent('Settings', `Server location Changed`, `New server is ${action.payload}`);
            persistThis("server", action.payload)
            state.perferences.server = action.payload;
        },
        logoutUser(state, action) {
            state.user = {};
            state.tokens = {};
            localStorage.removeItem("tokenObj");
            localStorage.removeItem("googleProfileObj");
            localStorage.removeItem("localProfileObj");
        },
    },
})


export const {
    setUser, 
    setTokens,
    openLoginModal, 
    closeLoginModal, 
    logoutUser,
    setServer,
    setAppTheme,
    setAutoTagging,
    setPushNotifState,
    showToast,
    closeToast,
    setUnseenNotifCount,
    setShowNotification
} = appMetaSlice.actions
