// ;
import React from 'react'
import Header from '../../components/header';
import Page from '../../components/page';
import { cls, sendGaEvent } from '../../utils';
import { Link } from 'react-router-dom';
import Button from '../../components/button';
import s from './style.module.css';

const isInTWA = document.referrer.includes('android-app://com.imgress.twa');

function LandingCDN (img) {
  return `https://imgress-imgs.s3.ir-thr-at1.arvanstorage.com/${  img}`;
}
function subm (label) {
  sendGaEvent('Home', 'CTA clicked', `cta button text: ${label}`)
}

function Image({...rest}) {
  return <img alt='' width='300' {...rest}/>
}

function Home () {
  return (
    <Page classNames={s.pkij}>
      <Header isHome ctaCallbacks={{
        login:()=>subm('login'),
        signup:()=>subm('signup'),
      }} />
      <br />
      <br />
      <section className={cls('p3')} >
        <h1 className='h3 mb2'>Do you?</h1>
        <ul className='ml2'>
          <li className='mb1'>Have a similar series of images and want to choose the best</li>
          <li className='mb1'>Want to pick the best cover image for somewhere</li>
          <li className='mb1'>Choose the best design, logo or drawing</li>
          <li className='mb1'>Select the better Image to post?</li>
        </ul>
      </section>
      {/* <section className='mt3 ml1 center'>
          <h1 className='h1'>Imgress</h1>
          <h2 className='h2 mt1'>Free Image voting platform</h2>
         <Image className={cls('', s.img)} src={LandingCDN('john-schnobrich-2FPjlAyMQTA-unsplash.jpg')} />
      </section> */}
      
      <section className='my3 center'>
        <h2 className="h2 my1">Let people help you decide</h2>
        <p className='mt1'>
          <Link to='/create'><Button color='primary' onClick={()=>subm('creat')} className='mt2'>Create a Poll</Button></Link> and  decide with <strong className='strong'>confidence</strong> </p>
        </section>
      
      {/* <section className='my2 center'>
        <h2 className='h2 mt2 mb1'>before posting to front-line</h2>
        <div className={s.fgsdte}>
          <Image className={s.hfh} src={LandingCDN('robin-worrall-FPt10LXK0cg-unsplash.jpg')} />
          <Image className={s.hfh} src={LandingCDN('priscilla-du-preez-BjhUu6BpUZA-unsplash.jpg')} />
          <Image className={s.hfh} src={LandingCDN('freestocks-m7zKB91brGo-unsplash.jpg')} />
        </div>
      </section> */}
      <section className='mb3 mt4 center'>
        <h2 className="h2 my1">Or you can Help others decide!</h2>
        <Link to='/ballots'><Button color='primary2' onClick={()=>subm('view')} className='my2'>View pollings and Vote!</Button></Link>
      </section>
      
      <section className='my3 center'>
        <h2 className='h2 mt2 mb1'>Privacy first</h2>
        <p>Ballots are private by default, <br /> only your followers can view / vote on them</p>
        <p className='mt1'>You can also create public that will appear in public feed and eveyone can vote </p>
      </section>
      {!isInTWA &&
      <section className='my3 center'>
        {/* <h2 className='mb2'>Create Your Image poll</h2> */}
          <>
            <a href='/Imgress.apk' download={true}><Button color='primary'>Download Android App</Button></a>
          </>
      </section>
    }
      <footer className='center'>
        @Imgress 2022
      </footer>
    </Page>
  )
}

export default Home;
